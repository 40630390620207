import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable, throwError, Observer, firstValueFrom, lastValueFrom, forkJoin, from, Subject, of, BehaviorSubject } from 'rxjs';
import { catchError, finalize, map, mergeMap, shareReplay, takeUntil, tap, toArray } from 'rxjs/operators';
import  *  as Alchemint from '@app/_alchemint/alchemint_dm';
import { AccCustomerInvoice, AccCustomerPaymentDTO, AddOnServiceDTO, AlchemedPreLoadData, AngularComponent, ApiKeyWithMasterPassword, ArtifactAndIsNewDTO, ArtifactChangeLogPerson, ArtifactSyncDTO, ArtiFormBundle,  ArtiFormInstanceWithArtifactPreview,  CorrectIntegratedPatientLinkDTO, CustomListDTO, CustomListExportDTO, DataModelInfo, eHealthCheckItemType, EmailDetails, EmailResultDTO, ExternalRqDetails, FullPatientDetails, HealthCheckItem, HelpVideoDTO, HistoricalArtifactDTO, iHealthAppointmentTypesDTO, iHealthPracticePractitionerDTONew, iHealthVenuesByLoginIdDTO, iHealthVenuesDTO, PatientFromScanStickerDTO, PatientHistory, PaymentAmountAgainstInvoiceAllocatorDTO, ReportDataDTO, SnapshotDetails, StringDTO, TemplateDocumentFeatureCode, TextDTO,  WhisperDTO } from '@app/_alchemint/alchemint_composite_requests';
import { EnvService } from '@app/_services/environment.service';
import { AlcConstants } from './alchemint.apiinterface.service';
import { ChatGPTOperationFormDTO, ChatGPTQuestionairreQuestion, ChatGPTQuestionnaireDTO } from '@app/form-builder/form-detail/form-detail.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ExerciseDetail } from '@app/_alchemint/msk-model';
import { promises } from 'dns';
import { CodeModifyInstruction } from '@app/simulator-interface.service';
@Injectable({
  providedIn: 'root'
})
export class WebApiInterfaceService {

  // Step 2: Declare and initialize the BehaviorSubject
  private requestPlayRrwebEventFile = new BehaviorSubject<string>(null);
  private requestPlayRrwebEventForText = new BehaviorSubject<string>(null);

  // Method to emit the name of the file to replay
  requestReplay(fileName: string) {
    this.requestPlayRrwebEventFile.next(fileName);
  }

  requestReplayForTextEvents(text: string) {
    this.requestPlayRrwebEventForText.next(text);
  }

  // Method to get the observable to subscribe to
  getRequestPlayObservable() {
    return this.requestPlayRrwebEventFile.asObservable();
  }


  // Method to get the observable to subscribe to
  getRequestPlayForTextObservable() {
    return this.requestPlayRrwebEventForText.asObservable();
  }
  public aiEventSubject = new BehaviorSubject<string>(null);
  public aiEvent$ = this.aiEventSubject.asObservable();


  public aiEventSubjectFinished = new BehaviorSubject<string>(null);
  public aiEventFinished$ = this.aiEventSubjectFinished.asObservable();
    
  public medicalLenseCustomListValues: string [] = null;

  // public initialiseiHealthData (preLoadData: AlchemedPreLoadData)
  // {

  //   if (preLoadData.newIHealthApiMode)
  //   {
  //     // this.getiHealthVenues().subscribe((data) => {
  //     //   this.cachediHealthVenues = data;
  //     // });
  
  //     this.getiHealthVenuesbyLoginid().subscribe((data) => {
  //       this.cachediHealthVenuesByLoginId = data;
  //     });

  //     this.getiHealthAppointmentTypes().subscribe((data) => {
  //       this.cachediHealthAppointmentTypes = data;
  //     });
  //   }
 
  // }

// Class member to hold the shared observable
private initialiseiHealthDataObservable: Observable<boolean> | null = null;
// Class member to control the lifecycle of the observable
private destroy$ = new Subject<void>();

public initialiseiHealthData(preLoadData: AlchemedPreLoadData): Observable<boolean> {
  // Check if the observable already exists and return it to avoid multiple API calls
  if (this.initialiseiHealthDataObservable) {
    return this.initialiseiHealthDataObservable;
  }

  if (!preLoadData.newIHealthApiMode) {
    // If the new API mode is not enabled, directly return an observable that emits false
    this.initialiseiHealthDataObservable = of(false);
  } else {
    // Use forkJoin to make concurrent HTTP requests
    this.initialiseiHealthDataObservable = forkJoin({
      venues: this.getiHealthVenuesbyLoginid().pipe(catchError(error => of(null))),
      appointmentTypes: this.getiHealthAppointmentTypes().pipe(catchError(error => of(null))),
    }).pipe(
      map(results => {
        // Update cached values with the results if they exist
        this.cachediHealthVenuesByLoginId = results.venues || [];
        this.cachediHealthAppointmentTypes = results.appointmentTypes || [];
        
        // Returning true to indicate successful completion
        return true;
      }),
      catchError(error => {
        // Handle any errors that occurred during the forkJoin operation
        console.error('Error initializing iHealth data:', error);
        return of(false); // Return false to indicate failure
      }),
      shareReplay(1), // Share the latest result with all subscribers and replay it for any late subscribers
      takeUntil(this.destroy$) // Ensure the observable completes if the destroy subject emits
    );
  }

  return this.initialiseiHealthDataObservable;
}

// Call this method to clean up the observable when the service is destroyed
public ngOnDestroy(): void {
  this.destroy$.next();
  this.destroy$.complete();
}
  
  public CreateVideoCallSession(sessionId: string): Promise<string> {
  
    var queryString =  this.apiExtendedUrl + `videocall/sessions`;
  
    return lastValueFrom(this.http.post(
      queryString,
      { customSessionId: sessionId },
      { headers: { 'Content-Type': 'application/json' }, responseType: 'text' }
    ));
  }

  public CreateVideoCallToken(sessionId: string): Promise<string> {
    
    var queryString =  this.apiExtendedUrl + `videocall/sessions/` + sessionId + '/connections';
    return lastValueFrom(this.http.post(
      queryString,
      {},
      { headers: { 'Content-Type': 'application/json' }, responseType: 'text' }
    ));

  }


  public organizationLogo: string;

  public clearCache()
  {
    this.organizationLogo = null;
  }


  public getPatientWithFullDetailsWebGuiApiCall(patientId: string): Observable<FullPatientDetails> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/fullpatientdetails/' + patientId;
    var ret$ = this.http.get<FullPatientDetails>(queryStirng, { headers: hds });
    return ret$;
  }

  public getPayAsYouGoBalance(): Observable<number>
  {
    const typeCode: string = 'SMS';
    var queryString =  this.apiExtendedUrl + `purchasing/getbalance/${typeCode}`;
    return this.http.get<number>(queryString).pipe(catchError((err) => { return this.HandleError(err);}));
  }
  public isBalanceSufficientToShareFiles(): Observable<boolean>
  {
    const typeCode: string = 'SMS';
    var queryString =  this.apiExtendedUrl + `purchasing/isbalancesufficienttosharefiles`;
    return this.http.get<boolean>(queryString).pipe(catchError((err) => { return this.HandleError(err);}));
  }
  public updateUserConfigurations(configs: Alchemint.UserCustomConfiguration[]): Observable<Alchemint.UserCustomConfiguration[]>
  {
    var queryString =  this.apiExtendedUrl + `sec/usermanagement/updateusercustomconfig`;
    return this.http.post<Alchemint.UserCustomConfiguration[]>(queryString, configs).pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public updateconsult(consult: Alchemint.Consult): Observable<boolean>
  {
    var queryString =  this.apiExtendedUrl + `diary/updateconsult`;
    return this.http.put<boolean>(queryString, consult).pipe(catchError((err) => { return this.HandleError(err);}));
  }

  // public addConsult(consult: Alchemint.Consult): Observable<boolean>
  // {
    
  //   if (!consult.id)
  //   {
  //     consult.id = this.generateGuid(false);
  //   }
    
  //   var queryString =  this.apiExtendedUrl + `diary/addconsult`;
  //   return this.http.put<boolean>(queryString, consult).pipe(catchError((err) => { return this.HandleError(err);}));
  // }

  public addConsult(consult: Alchemint.Consult): Observable<Alchemint.Consult>
  {
    var queryString =  this.apiExtendedUrl + `diary/addconsultnew`;
    return this.http.put<Alchemint.Consult>(queryString, consult).pipe(catchError((err) => { return this.HandleError(err);}));
  }



  public getUserCustomConfig(): Observable<Alchemint.UserCustomConfiguration[]>
  {
    var queryString =  this.apiExtendedUrl + `sec/usermanagement/getusercustomconfig`;
    return this.http.get<Alchemint.UserCustomConfiguration[]>(queryString).pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getUsers(): Observable<Alchemint.Login[]>
  {
    var queryString =  this.apiExtendedUrl + `sec/usermanagement/logins/false`;
    return this.http.get<Alchemint.Login[]>(queryString).pipe(catchError((err) => { return this.HandleError(err);}));
  }



  public topUpPayAsYouGoBalance(topUpAmount: number): Observable<number>
  {
    const typeCode: string = 'SMS';
    var queryString =  this.apiExtendedUrl + `purchasing/topupbalance/${typeCode}/${topUpAmount}`;
    return this.http.post<number>(queryString, null).pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public uploadMultipleArtifacts(patientId: string, artifactTypeId: string, formData: FormData) : Observable<Alchemint.Artifact[]>
  {
    var queryString =  this.apiExtendedUrl + `patientmanagement/uploadfilestopatient/${patientId}/${artifactTypeId}`;
    return this.http.post<Alchemint.Artifact[]>(queryString, formData)
      .pipe(catchError((err) => { return this.HandleError(err);}));
 }

//  public updatePatientSequenceNumbers() : Observable<boolean>
//  {
//    var queryString =  this.apiExtendedUrl + `patientmanagement/updatepatientsequencenumbers`;
//    return this.http.post<boolean>(queryString, null)
//      .pipe(catchError((err) => { return this.HandleError(err);}));
//  }




  errorMsg: string;
  artifactTypes : Alchemint.ArtifactType [] = null;
  public ICD_CACHE_KEY : string = "icd10cache";

  private currDate : Date;
  private timeZoneHoursOffset : number;

  private cachedICD10CodesObservable : Observable<Alchemint.ICD10Code[]> = null;
  private preloadWebCall$ : Observable<AlchemedPreLoadData> = null;;


  public MAX_ALLOWED_FILE_SIZE_MB : number = 3;
  public MAX_ALLOWED_FILE_SIZE : number =  this.MAX_ALLOWED_FILE_SIZE_MB * 1024 * 1024;

  private _maxFileSize: number = this.MAX_ALLOWED_FILE_SIZE;
  public REPORTING_MAX_ROWS: number = 10000;
  private _maxFileSizeMinumumThreshold: number = 1 & 1024 * 1024;

   public get maxFileSize () : number
   {
     if (this._maxFileSize < this._maxFileSizeMinumumThreshold)
     {
      return this._maxFileSizeMinumumThreshold;
     }
     else
     {
      return this._maxFileSize;
     }

   }

   public set maxFileSize (value : number)
   {
      this._maxFileSize = value;;
   }



  _userHasBeenWarnedAboutAI: boolean = false; 

  constructor(
    private http: HttpClient,
    private envService: EnvService,
    private sanitizer: DomSanitizer,
    ) {

    this.currDate = new Date();
    this.timeZoneHoursOffset = Math.round(((-1) *  (this.currDate.getTimezoneOffset() / 60)));

    
    this._userHasBeenWarnedAboutAI = (localStorage.getItem("userHasBeenWarnedAboutAI") === 'true')  


  }

  public get userHasBeenWarnedAboutAI() : boolean
  {
    return this._userHasBeenWarnedAboutAI;
  }
  public set userHasBeenWarnedAboutAI(value : boolean)
  {
    this._userHasBeenWarnedAboutAI = value;
    localStorage.setItem("userHasBeenWarnedAboutAI", value.toString());
  }


  environment = this.envService.deploymentSettings;

  public apiUri = this.environment.apiUrl;
  artifactEndpoint = this.environment.artifactServiceEndPoint;
  publicContentEndPoint = this.environment.publicContentEndPoint;
  apiStatsUrl = this.environment.apiStatsUrl;
  apiWebUiUrl = this.environment.apiWebUiUrl;
  apiExtendedUrl = this.environment.apiExtendedUrl;

  cachedArtiForms : Observable<Alchemint.ArtiForm[]> = null;
  cachedArtiFactTypes : Observable<Alchemint.ArtifactType[]> = null;
  _cachedPreLoadData : Observable<AlchemedPreLoadData> = null;
  cachedAndDownloadedPreloadData : AlchemedPreLoadData = null;



  public clientCodeVersion : string;



  public getEntities <T>(emptyEntity : Alchemint.IAlchemintEntity): Observable<T[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/getall`;
    return this.http.get<T[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getEntityByKey <T>(emptyEntity : Alchemint.IAlchemintEntity, keyName : string, keyValue: string): Observable<T>
  {
    var queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/getfirstbykey/${keyName}/${keyValue}`;
    return this.http.get<T>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getAllEntitiesByKey <T>(emptyEntity : Alchemint.IAlchemintEntity, keyName : string, keyValue: string): Observable<T[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/getfirstallbykeyfield/${keyName}/${keyValue}`;
    return this.http.get<T[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getNamedEntitiesByKey <T>(entitTypeName : string, keyName : string, keyValue: string): Observable<T[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'bridge/' + `${entitTypeName}/getfirstallbykeyfield/${keyName}/${keyValue}`;
    return this.http.get<T[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public searchForObject<T>(emptyEntity : Alchemint.IAlchemintEntity, searchField: string, searchValue: string): Observable<T[]> {
    var queryString =  this.apiExtendedUrl + `bridge/${emptyEntity.entityTypeName}/searchobjectsbyNamedField/${searchField}/${searchValue}`;
    return this.http.get<T[]>(queryString).pipe(catchError((err) => { return this.HandleError(err);}));
  }

  searchForObjectById <T>(emptyEntity : Alchemint.IAlchemintEntity, searchValue: string): Observable<T[]> {
    var queryString =  this.apiExtendedUrl + `bridge/${emptyEntity.entityTypeName}/searchobjectsbyNamedField/Id/${searchValue}`;
    return this.http.get<T[]>(queryString ).pipe(catchError((err) => { return this.HandleError(err);}));
  }
   



  public getEntityByMatchingProperties <T>(emptyEntity : Alchemint.IAlchemintEntity, entity : T, sort : string = null): Observable<T[]>
  {
    const queryStirng = this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/getbyquery` + (sort == null ? '' : `/${sort}`)  + "?" + this.queryParamatersStringFromEntity<T>(entity);
    return this.http.get<T[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }



  public getEntitiesByProperyStartsWith <T>(emptyEntity : Alchemint.IAlchemintEntity, propertyName : string,wildcard: string, orderByProperty : string = null, orderByDirection : string = "asc"): Observable<T[]>
  {
    let queryStirng = this.apiUri + emptyEntity.entityTypeName + `/simple?$filter=startswith('${encodeURIComponent(wildcard)}',${propertyName})=true`;
    let orderByString = "";
    if (orderByProperty)
    {
      orderByString = encodeURIComponent("&orderBy=" + orderByProperty + " " + orderByDirection);
      queryStirng = queryStirng + orderByString;
    }
    return this.http.get<T[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getEntitiesByNamedField <T>(emptyEntity : Alchemint.IAlchemintEntity, propertyName : string,  propertyValue: string, sortProperty : string = null): Observable<T[]>
  {
    let queryStirng = this.apiUri + emptyEntity.entityTypeName + `/simple?$filter=${propertyName}${encodeURIComponent(` eq `)}${encodeURIComponent(propertyValue)}`;
    if (sortProperty != null) {  queryStirng += `&$orderBy=${sortProperty} asc`; }
    return this.http.get<T[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }



  public getEntity <T>(emptyEntity : Alchemint.IAlchemintEntity, id : string): Observable<T>
  {
    const queryStirng = this.apiUri + emptyEntity.entityTypeName + "/" + id;
    return this.http.get<T>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  // public getEntity <T>(emptyEntity : Alchemint.IAlchemintEntity,id : string): Observable<T>
  // {
  //   var queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/get` + "/" + id;
  //   return this.http.get<T>(queryStirng)
  //     .pipe(catchError((err) => { return this.HandleError(err);}));
  // }


  // public getEntityByMatchingProperties <T>(emptyEntity : Alchemint.IAlchemintEntity, entity : T): Observable<T[]>
  // {
  //   const queryStirng = this.apiUri + emptyEntity.entityTypeName + "?" + this.queryParamatersStringFromEntity<T>(entity);
  //   return this.http.get<T[]>(queryStirng)
  //     .pipe(catchError((err) => { return this.HandleError(err);}));
  // }




  public getAlchemintSettings (): Observable<Alchemint.SpaSettings>
  {
    return this.http.get<Alchemint.SpaSettings>('assets/alchemint.txt')
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public createEntity <T>(emptyEntity : Alchemint.IAlchemintEntity, entity : T): Observable<T>
  {
    const queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/add`;
    return this.http.post<T>(queryStirng, entity)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public createEntities <T>(emptyEntity : Alchemint.IAlchemintEntity, entities : T[]): Observable<T[]>
  {
    const queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/addmultiple`;
    return this.http.post<T[]>(queryStirng, entities)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public updateEntity <T>(emptyEntity : Alchemint.IAlchemintEntity, entity : T): Observable<T>
  {
    const queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/update`;
    return this.http.put<T>(queryStirng, entity)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public addOrupdateEntity <T>(emptyEntity : Alchemint.IAlchemintEntity, entity : T): Observable<T>
  {
    const queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/addorupdate`;
    return this.http.post<T>(queryStirng, entity)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public cloneArtifact(id: string, newName: string) : Observable<Alchemint.Artifact>
  {
    const queryStirng =  this.apiExtendedUrl + 'artiformmanagement/cloneartifact/' + `${id}/${newName}`;
    return this.http.post<Alchemint.Artifact>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public setHtmlDoctoA4Width(id: string) : Observable<Alchemint.Artifact>
  {
    return this.changeHtmlDocWidth(id,16);
  }


  public changeHtmlDocWidth(id: string, width: number) : Observable<Alchemint.Artifact>
  {
    const queryStirng =  this.apiExtendedUrl + 'artiformmanagement/sethtmldoctoa4width/' + `${id}/${width}`;
    return this.http.post<Alchemint.Artifact>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  // public sizeAndStyleHtmlForA4(id: string) : Observable<Alchemint.Artifact>
  // {
  //   const queryStirng =  this.apiExtendedUrl + 'artiformmanagement/sizeandstylehtmlfor/' + `${id}`;
  //   return this.http.post<Alchemint.Artifact>(queryStirng, null)
  //     .pipe(catchError((err) => { return this.HandleError(err);}));
  // }

  // public updateEntity <T>(emptyEntity : Alchemint.IAlchemintEntity, entity : T): Observable<T>
  // {
  //   const queryStirng = this.apiUri + emptyEntity.entityTypeName;
  //   return this.http.put<T>(queryStirng, entity)
  //     .pipe(catchError((err) => { return this.HandleError(err);}));
  // }

  public deleteEntity <T>(emptyEntity : Alchemint.IAlchemintEntity, id : string ): Observable<T>
  {
    const queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/delete/${id}`;
    return this.http.delete<T>(queryStirng,)
    .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getReportData (name : string, biReportId : string, paramaters : string, page: number, maxRows: number): Observable<ReportDataDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' +'getreportdata/' + `${name}/${page}/${maxRows}` +
    (biReportId == null ? ``: `/${biReportId}`);
    (paramaters == null ? ``: `/${paramaters}`);
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getDataDictionary (): Observable<DataModelInfo[]>
  {
    var queryStirng : string = this.apiExtendedUrl + 'DataDictionary/' +'getdatadictionary';
    return this.http.get<DataModelInfo[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  


  public describeEmail (artifactId: string, format: string): Observable<EmailDetails>
  {
    var queryStirng : string = this.apiExtendedUrl + `emailreader/describeemail/${artifactId}/${format}`;
    return this.http.get<EmailDetails>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getEmailAttachmentData (artifactId: string, attachmentNumber: number): Observable<any>
  {
    var queryStirng : string = this.apiExtendedUrl + `emailreader/getattachment/${artifactId}/${attachmentNumber}`;
    return this.http.get(queryStirng, { responseType: 'arraybuffer' })
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public verifyEmailExists (emailaddress: string): Observable<boolean>
  {
    var queryStirng : string = this.apiExtendedUrl + `emailreader/verifyemailexists/${emailaddress}`;
    return this.http.get<boolean>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getFinReportData(name : string): Observable<ReportDataDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' +'fin/' + `${name}/${null}/${null}`;
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getFinReportDataWithDateParams (name : string, dateParam1: string | number, dateParam2 : string| number): Observable<ReportDataDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' +'fin/' + `${name}/${dateParam1}/${dateParam2}`;
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getFinReportDataWithDateParamsAndMore (name : string, dateParam1: string | number, dateParam2 : string| number, param3): Observable<ReportDataDTO>
  {
    const more = !!param3? `/${param3}`:'';
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' +'fin/' + `${name}/${dateParam1}/${dateParam2}${more}`;
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getFormReportData (id: string, parentId : string, page: number): Observable<ReportDataDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' +'getformreportdata/' + `${id}/${page}/${this.REPORTING_MAX_ROWS}`;
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getSurgeryManagerData (fromDate: string, toDate: string): Observable<ReportDataDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' + `getsurgerymanagerdata/${this.REPORTING_MAX_ROWS}/${fromDate}/${toDate}`;
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public canDeletePatient(patientId: string): Observable<canDeletePatientDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'patientmanagement/' + `candeletepatient/${patientId}`;
    return this.http.get<canDeletePatientDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));

  }

  public getPatientsAgreementManagerData (all : boolean, fromDate: string, toDate: string): Observable<ReportDataDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' + `getpatientagreementsmanagerdata/${all}/${this.REPORTING_MAX_ROWS}/${fromDate}/${toDate}`;
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }
  public getCompositeRequestManagerData (fromDate: string, toDate: string): Observable<ReportDataDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' + `getcompositerequestmanagerdata/${this.REPORTING_MAX_ROWS}/${fromDate}/${toDate}`;
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }
  public getBillingInstructionData (fromDate: string, toDate: string): Observable<ReportDataDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' + `getbillinginstructionsdata/${this.REPORTING_MAX_ROWS}/${fromDate}/${toDate}`;
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getMissingInvoicePDFsData (fromDate: string, toDate: string): Observable<ReportDataDTO>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' + `getmissinginvoicepdfsdata`;
    return this.http.get<ReportDataDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public toggleWorkflowPauseStateBillingInstruction (id: string): Observable<boolean>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' + `toggleworkflowpausestatebillinginstruction/${id}`;
    return this.http.put<boolean>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public deleteBillingInstruction (id: string): Observable<boolean>
  {
    var queryStirng : string = this.apiExtendedUrl + 'reporting/' + `deletebillinginstruction/${id}`;
    return this.http.delete<boolean>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public addgeneralmeeting(consult: Alchemint.Consult) : Observable<Alchemint.Consult>
  {
    const queryStirng =  this.apiExtendedUrl + 'diary/addgeneralmeeting';
    return this.http.post<Alchemint.Consult>(queryStirng, consult)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getReportDataHtml <T>(name : string, biReportId : string, paramaters : string): Observable<ReportDto>
  {
    const queryStirng = this.apiExtendedUrl + 'reporting/' +'getreportdata/html/' + `${name}` +
    (biReportId == null ? ``: `/${biReportId}`);
    (paramaters == null ? ``: `/${paramaters}`);
    return this.http.get<ReportDto>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public initialiseorganization (): Observable<boolean>
  {
    const queryStirng = this.apiExtendedUrl + 'setup/initialiseorg';
    return this.http.post<boolean>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public importLetterAfterOrgCreated (): Observable<Alchemint.Artifact>
  {
    const queryStirng = this.apiExtendedUrl + 'setup/importletterafterorgcreated';
    return this.http.post<Alchemint.Artifact>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public synchronizeIntegratedUsers (): Observable<boolean>
  {
    const queryStirng = this.apiExtendedUrl + 'setup/synchronizeintegratedusers';
    return this.http.post<boolean>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public  doSynchronizeIntegratedUsers() : Observable<[boolean, any]>
  {
    return new Observable<[boolean, any]>(observer => {

    var sub = this.synchronizeIntegratedUsers().subscribe(
      res => {
        observer.next([res, ""]);
      },
      err => {
        observer.error(err)
      },
      () => { sub.unsubscribe();}

    );
    });
  }

  public correctintegratedpatientlink (correctIntegratedPatientLinkDTO : CorrectIntegratedPatientLinkDTO): Observable<boolean>
  {
    const queryStirng = this.apiExtendedUrl + 'patientmanagement/correctintegratedpatientlink';
    return this.http.put<boolean>(queryStirng, correctIntegratedPatientLinkDTO)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getArtifactFile (id : string): Observable<ArrayBuffer>
  {
    const queryStirng = this.apiExtendedUrl + `artifactmanagement/getartifactfile/${id}`;
    return this.http.get(queryStirng, {responseType : 'arraybuffer'})
    .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getClinicalNotesArtifact (patientId : string): Observable<Alchemint.Artifact>
  {
    const queryStirng = this.apiExtendedUrl + `artifactmanagement/getclinicalnotesartifact/${patientId}`;
    return this.http.get<Alchemint.Artifact>(queryStirng)
    .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public fileLocationForRestores (artifactId : string): Observable<TextDTO>
  {
    const queryStirng = this.apiExtendedUrl + `artifactmanagement/filelocationforrestores/${artifactId}`;
    return this.http.get<TextDTO>(queryStirng).pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getArtifactFileWithRangesSync(id: string): Observable<ArrayBuffer> {
    //const chunkSize = 1048576; // 1MB per chunk, adjust as needed
    const chunkSize = 104857; // 1MB per chunk, adjust as needed
    let start = 0;
    let end = chunkSize - 1;
    let isComplete = false;
    let resultBuffer = new ArrayBuffer(0);

    return new Observable<ArrayBuffer>((observer) => {
      const downloadChunk = () => {
        if (isComplete) {
          observer.next(resultBuffer);
          observer.complete();
          return;
        }

        this.http.get(`${this.apiExtendedUrl}artifactmanagement/getartifactfilewithranges/${id}`, {
          headers: { 'Range': `bytes=${start}-${end}` },
          responseType: 'arraybuffer'
        })
        .pipe(
          catchError((err) => { 
            isComplete = true;
            return this.HandleError(err);
          }),
          finalize(() => {
            start += chunkSize;
            end += chunkSize;
            downloadChunk(); // Recursively download next chunk
          })
        )
        .subscribe((chunk: ArrayBuffer) => {
          let tmp = new Uint8Array(resultBuffer.byteLength + chunk.byteLength);
          tmp.set(new Uint8Array(resultBuffer), 0);
          tmp.set(new Uint8Array(chunk), resultBuffer.byteLength);
          resultBuffer = tmp.buffer;
          if (chunk.byteLength < chunkSize) {
            isComplete = true;
          }
        });
      };

      downloadChunk();
    });
  }

  private chunkSize = 4048570; // 100KB per chunk, adjust as needed



  public getArtifactFileWithRanges(id: string, fileSize: number): Observable<ArrayBuffer> {
    const totalChunks = Math.ceil(fileSize / this.chunkSize);
    const requests = Array.from({ length: totalChunks }, (_, index) => {
      const start = index * this.chunkSize;
      const end = Math.min((index + 1) * this.chunkSize - 1, fileSize - 1);
      return this.http.get(`${this.apiExtendedUrl}artifactmanagement/getartifactfilewithranges/${id}`, {
        headers: { 'Range': `bytes=${start}-${end}` },
        responseType: 'arraybuffer'
      }).pipe(
        catchError(error => {
          console.error(`Error downloading chunk ${index + 1}:`, error);
          return []; // Continue with other requests even if one fails
        })
      );
    });

    return forkJoin(requests).pipe(
      mergeMap(chunks => from(chunks)),
      toArray(),
      mergeMap(chunks => {
        if (chunks.length === 0) {
          throw new Error('No chunks were downloaded.');
        }
        // Calculate the total length of all chunks
        const totalLength = chunks.reduce((acc, chunk) => acc + chunk.byteLength, 0);
        // Create a new array buffer to hold all chunks
        let resultBuffer = new Uint8Array(totalLength);
        // Copy each chunk into the resultBuffer
        let position = 0;
        for (let chunk of chunks) {
          resultBuffer.set(new Uint8Array(chunk), position);
          position += chunk.byteLength;
        }
        return [resultBuffer.buffer]; // Convert back to ArrayBuffer
      }),
      catchError(err => {
        // Handle errors
        console.error('An error occurred:', err);
        throw err; // Rethrow or handle as needed
      })
    );
  }

  async getUnzippedFileDataForArtifact(artifactId: string): Promise<ArrayBuffer>
  {
    var afFile: any;

    afFile = await firstValueFrom(this.getArtifactFile(artifactId));

    var unzipped = await this.unzipArrayBufffer(afFile);

    return unzipped;

  }

  async getUnzippedFileDataForArtifactWithRanges(artifactId: string, filelength: number): Promise<ArrayBuffer>
  {
    var afFile: any;

    afFile = await firstValueFrom(this.getArtifactFileWithRanges(artifactId, filelength));

    var unzipped = await this.unzipArrayBufffer(afFile);

    return unzipped;

  }


  async unzipArrayBufffer(afFile: ArrayBuffer): Promise<ArrayBuffer>
  {
    // var zipper: JSZip = new JSZip();

    const JSZipModule: any = await import('jszip');
    const JSZip = JSZipModule.default; // Explicitly use the default export
    const zipper = new JSZip();

    var zszip = await zipper.loadAsync(afFile);
    var filedata: ArrayBuffer;
    // New code to extract the first file as ArrayBuffer
    var fileNames = Object.keys(zszip.files);
    if (fileNames.length > 0) {
        var firstFileName = fileNames[0];
        var firstFile = zszip.files[firstFileName];
        filedata = await firstFile.async('arraybuffer');
        return filedata;
        // Now arrayBuffer contains the content of the first file in ArrayBuffer format
        // You can proceed with further processing here
    }
    else
    {
      throw new Error("The zip file is empty");

    }
  }




  public createArtifact (parentId : string, file : File, artifactTypeId : string, functionalCode : string, id : string): Observable<Alchemint.Artifact>
  {
    const queryParams = this.getArtifactQueryParamaters(file.name, parentId, artifactTypeId, file.size, functionalCode, id);
    const queryString = this.environment.apiUrl + this.environment.artifactServiceEndPoint + "/withfile?" + queryParams;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<Alchemint.Artifact>(queryString, formData, {reportProgress : true}
      )
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public updateArtifact (artifact : Alchemint.Artifact, file : File): Observable<Alchemint.Artifact>
  {
    artifact.unCompressedFileSize = file.size;
    const queryParams = this.jsonSerializeArtifact(artifact);
    const queryString = this.environment.apiUrl + this.environment.artifactServiceEndPoint + "/withfile?" + queryParams;
    const formData = new FormData();

    formData.append('file', file);
    return this.http.put<Alchemint.Artifact>(queryString, formData)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public updateMemoFile(artifact: Alchemint.Artifact, file: File): Observable<Alchemint.Artifact> {
    artifact.unCompressedFileSize = file.size;

    var queryString = this.apiExtendedUrl + 'artifactmanagement' + '/updatememo';

    const formData = new FormData();

    formData.append('file', file);
    return this.http.put<Alchemint.Artifact>(queryString, formData)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }



  public deleteArtifact (id : string): Observable<Alchemint.Artifact>
  {
    const queryString = this.environment.apiUrl + this.environment.artifactServiceEndPoint + "/withfile/" + id;
    return this.http.delete<Alchemint.Artifact>(queryString)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }




  public getPublicContent (publicConytentKey : string, functionalCode : string): Observable<Alchemint.TextContent>
  {
    const queryStirng = this.apiUri + this.publicContentEndPoint + `/${publicConytentKey}/${functionalCode}`;
    return this.http.get<Alchemint.TextContent>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getWebAppItems (publicConytentKey : string, type : string): Observable<Alchemint.WebAppItem []>
  {
    const queryStirng = this.apiUri + this.publicContentEndPoint + `/webappitems` + `/${publicConytentKey}/${type}`;
    return this.http.get<Alchemint.WebAppItem[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public getPublicByteContent (publicConytentKey : string, functionalCode : string): Observable<any>
  {
    const queryStirng = this.apiUri + this.publicContentEndPoint + `/${publicConytentKey}/bin/${functionalCode}`;
    return this.http.get(queryStirng, {responseType : 'arraybuffer'})
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }



  public getPerformanceStats (uriAppend : string, goLangTimePeriod : string): Observable<Alchemint.PerfStats[]>
  {
    let queryStirng = this.apiStatsUrl + `PerformanceStats/history/${uriAppend}/${goLangTimePeriod}`;
    return this.http.get<Alchemint.PerfStats[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getUsageStats (uriAppend : string, year : number, month : number ): Observable<Alchemint.OrgStats[]>
  {
    let queryStirng = this.apiStatsUrl + `PerformanceStats/orgstathist/${uriAppend}/${year}/${month}`;
    return this.http.get<Alchemint.OrgStats[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getExternalWebRequestApiCall (requestDetails : string, requestId : string, webUIControllerapiKey : string, resultsRequested : boolean): Observable<ExternalRqDetails>
  {
    var hds = {
      Accept: 'application/json',
      WebUIControllersApiKey: webUIControllerapiKey
    };

    var queryStirng =  this.apiWebUiUrl + 'externalapirequest' + '/'
    if (resultsRequested == true)
    {
      queryStirng += 'results/';
    }
    queryStirng += encodeURIComponent(requestDetails) + '/' + encodeURIComponent(requestId);


    var ret = this.http.get<ExternalRqDetails>(queryStirng, { headers : hds })
      .pipe(catchError(
        (err) => {
           return this.HandleError(err);
          }));
    return ret;

  }


  public getArtifactThumbnail (artifactid : string): Observable<ArrayBuffer>
  {

    var hds = {
      Accept: 'application/json'

    };

    var queryStirng =  this.apiExtendedUrl + 'webuserinterface' + '/getartifactthumbnail/' + `${artifactid}`;
    return this.http.get<ArrayBuffer>(queryStirng)
      .pipe(catchError((err) => {
        return this.HandleError(err);
      }));
  }

  public getArtifactsWithThumbnails (patientId : string): Observable<Alchemint.ArtifactWithFileData[]>
  {

    var hds = {
      Accept: 'application/json'

    };

    var queryStirng =  this.apiExtendedUrl + 'webuserinterface' + '/getartifactthumbnails/' + `${patientId}`;
    return this.http.get<Alchemint.ArtifactWithFileData[]>(queryStirng)
      .pipe(catchError((err) => {
        return this.HandleError(err);
      }));
  }


  public getArtifactThumbnailBase64 (artifactid : string): Observable<string>
  {
    return new Observable<string> (
        observer => {
          var subscription = this.getArtifactThumbnail(artifactid).subscribe(
            byteData => {


              var imageString : string = 'data:image/png;base64,' + byteData;

              //var imageString = this.imageArrayToBase64(byteData);
              // var base64 = this.arrayBufferToBase64 (byteData);
              // var imageString : string = 'data:image/png;base64,' + base64;
              subscription.unsubscribe();
              observer.next(imageString);
            },
            err => {
              subscription.unsubscribe();
              observer.error(err);
            }
          );
        }
    );
  }

  imageArrayToBase64 (arrayBuffer : ArrayBuffer)
  {
    var base64 = this.arrayBufferToBase64 (arrayBuffer);
    var imageString : string = 'data:image/png;base64,' + base64;
    return imageString;
  }


  private arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );

  }


  public getPreAnaestheticReportForSurgery (surgeryid : string): Observable<ExternalRqDetails>
  {
    var queryStirng =  this.apiExtendedUrl + 'webuserinterface' + '/preanaesthesiareport/' + `${surgeryid}`;
    return this.http.get<ExternalRqDetails>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public getPatientHistoricalNoteArtifacts (patientId : string): Observable<HistoricalArtifactDTO>
  {
    var queryStirng =  this.apiExtendedUrl + 'artifactmanagement' + '/getpatienthistoricalartifacts/' + `${patientId}`;
    return this.http.get<HistoricalArtifactDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getSupportReplayArtifacts (): Observable<Alchemint.Artifact[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'artifactmanagement' + '/getsupportreplayartifacts';
    return this.http.get<Alchemint.Artifact[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }





  public getArtiForms()  : Observable<Alchemint.ArtiForm[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'artiformmanagement' + '/getforms';
    return this.http.get<Alchemint.ArtiForm[]>(queryStirng).pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public getArtiFormsWithNameLongerThanTwoChars()  : Observable<Alchemint.ArtiForm[]>
  {
    return this.getArtiForms().pipe(map(data => data.filter(x => x.name.length > 2)));
  }


  public getArtiFormsFromSharelLibrary () : Observable<Alchemint.ArtiForm[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'artiformmanagement' + '/getsharedlibraryforms';
    return this.http.get<Alchemint.ArtiForm[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));

  }

  public getTemplatesFromSharelLibrary () : Observable<Alchemint.Artifact[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'artiformmanagement' + '/getsharedlibrarytemplates';
    return this.http.get<Alchemint.Artifact[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));

  }

  public getTemplatesFromSharelLibrarOfFunctionalCode (functionalCode: string) : Observable<Alchemint.Artifact>
  {
    var queryStirng =  this.apiExtendedUrl + 'artiformmanagement' + `/getsharedlibrarytemplatebyfunctionalcode/${functionalCode}`;
    return this.http.get<Alchemint.Artifact>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));

  }


  public getArtiFormFields(artiFormId: string)  : Observable<Alchemint.ArtiFormFld[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'artiformmanagement' + '/getfields/' + `${artiFormId}`;
    return this.http.get<Alchemint.ArtiFormFld[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  private _getArtiFormFieldTypesReplay :  Observable<Alchemint.ArtiFormFldType[]>;
  public _getArtiFormFieldTypes()  : Observable<Alchemint.ArtiFormFldType[]>
  {
    if (!this._getArtiFormFieldTypesReplay)
    {
      var queryStirng =  this.apiExtendedUrl + 'artiformmanagement' + '/getfieldtypes';
      this._getArtiFormFieldTypesReplay = this.http.get<Alchemint.ArtiFormFldType[]>(queryStirng).pipe(shareReplay())
        .pipe(catchError((err) => { return this.HandleError(err);}));
    }
    return this._getArtiFormFieldTypesReplay;
  }

  public getArtiFormFieldTypes()  : Observable<Alchemint.ArtiFormFldType[]>
  {
    return this._getArtiFormFieldTypes();
  }

  public createandlinktemplateToForm (artiFormId : string): Observable<Alchemint.Artifact>
  {
    const queryStirng = this.apiExtendedUrl + 'artiformmanagement/createandlinktemplate/' + artiFormId;
    return this.http.post<Alchemint.Artifact>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public createTemplate (name: string): Observable<Alchemint.Artifact>
  {
    const queryStirng = this.apiExtendedUrl + 'setup/createtemplate/' + name;
    return this.http.post<Alchemint.Artifact>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public exportArtiForm (artiFormId : string):  Observable<string>
  {
    var hds = {
      Accept: 'application/json' //,
    };

    const queryStirng = this.apiExtendedUrl + 'artiformmanagement/exportform/' + artiFormId;
    return this.http.get<string>(queryStirng, {headers: hds})
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public exportCustomlist (custlisId : string):  Observable<string>
  {
    var hds = {
      Accept: 'application/json' //,
    };

    const queryStirng = this.apiExtendedUrl + 'customlists/exportcustomlist/' + custlisId;
    return this.http.get<string>(queryStirng, {headers: hds})
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public exportCustomlistAsFile (custListId : string):  Observable<Blob>
  {
    return new Observable<Blob> (
      observer=> {
        this.exportCustomlist(custListId).subscribe(
          fileText => {
            observer.next(new Blob([fileText]));
          },
          err =>
          {
            observer.error(err);
          }
        );

      }
    );
  }
  


  public exportArtiFormWithBlankIds (artiFormId : string):  Observable<string>
  {
    var hds = {
      Accept: 'application/json' //,
    };

    const queryStirng = this.apiExtendedUrl + 'artiformmanagement/exportformwithblankids/' + artiFormId;
    return this.http.get<string>(queryStirng, {headers: hds})
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }




  public exportAllArtiFormAsFile () :Observable<boolean>
  {
    return new Observable<boolean>(observer => {
    // Set up the request headers with responseType 'blob'
    const headers = new HttpHeaders({ 'Content-Type': 'application/zip' });
    const requestOptions = { headers: headers, responseType: 'arrayBuffer' as 'json' };
    const queryStirng = this.apiExtendedUrl + 'artiformmanagement/exportallforms';


    // Make the GET request to the API
    this.http.get<ArrayBuffer>(queryStirng, requestOptions).subscribe((response) => {
      // Create an anchor element to download the file
      const a = document.createElement('a');
      const blob = new Blob([response], { type: 'application/octet-stream' });

      const url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = 'myZipFile.zip'; // Set your desired file name
      a.click();
      observer.next(true);
      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    }, error => {
      observer.error(error);
      console.error('Error downloading the file:', error);
    });


    // return this.http.get<ArrayBuffer>(queryStirng, {headers: hds})
    //   .pipe(catchError((err) => { return this.HandleError(err);}));

    });


  }

  public exportArtiFormAsFile (artiFormId : string):  Observable<Blob>
  {
    return new Observable<Blob> (
      observer=> {
        this.exportArtiForm(artiFormId).subscribe(
          fileText => {
            observer.next(new Blob([fileText]));
          },
          err =>
          {
            observer.error(err);
          }
        );

      }
    );
  }

  public createCustomList (customListDTO: CustomListDTO): Observable<CustomListDTO>
  {
    const queryStirng = this.apiExtendedUrl + 'artiformmanagement/createcustomlist';
    return this.http.post<CustomListDTO>(queryStirng, customListDTO)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public cloneformfromsharedlibrary (id: string): Observable<ArtiFormBundle>
  {
    const queryStirng = this.apiExtendedUrl + 'artiformmanagement/cloneformfromsharedlibrary/' + id;
    return this.http.post<ArtiFormBundle>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public cloneform (id: string): Observable<ArtiFormBundle>
  {
    const queryStirng = this.apiExtendedUrl + 'artiformmanagement/cloneform/' + id;
    return this.http.post<ArtiFormBundle>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public cloneformWithNewName (id: string, newName: string): Observable<ArtiFormBundle>
  {
    var newNameEncoded = encodeURIComponent (newName);

    const queryStirng = this.apiExtendedUrl + 'artiformmanagement/cloneform/' + id + '/' + newNameEncoded;
    return this.http.post<ArtiFormBundle>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public clonetemplatefromsharedlibrary (id: string): Observable<Alchemint.Artifact>
  {
    const queryStirng = this.apiExtendedUrl + 'artiformmanagement/clonetemplatefromsharedlibrary/' + id;
    return this.http.post<Alchemint.Artifact>(queryStirng, null)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public requestContactForFeatureEnable (feature: string): Observable<boolean>
  {
    var addOnServiceDTO: AddOnServiceDTO = new AddOnServiceDTO();
    addOnServiceDTO.serviceName = feature;
    const queryStirng = this.apiExtendedUrl + `addonservices/requestaddonserviceinformation`;
    return this.http.post<boolean>(queryStirng, addOnServiceDTO)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public requestFeatureEnable (feature: string): Observable<boolean>
  {
    var addOnServiceDTO: AddOnServiceDTO = new AddOnServiceDTO();
    addOnServiceDTO.serviceName = feature;
    const queryStirng = this.apiExtendedUrl + `addonservices/requestaddonservicebeenabled`;
    return this.http.post<boolean>(queryStirng, addOnServiceDTO)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }




  public getCustomLists()  : Observable<Alchemint.CustomList[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'customlists' + '/getcustomlists';
    return this.http.get<Alchemint.CustomList[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public getCustomList(customListId: string)  : Observable<CustomListDTO>
  {
    var queryStirng =  this.apiExtendedUrl + 'artiformmanagement' + '/getcustomlist/' + `${customListId}`;
    return this.http.get<CustomListDTO>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  private _getObeserverError (error : string) : Observable<any>
  {
    return new Observable<any>
    (
      observer => {observer.error(error)}
    );

  }



  // private _preLoadDatObservable : Observable<AlchemedPreLoadData> = null;
  // public getPreLoadDataWebGuiApiCall () : Observable<AlchemedPreLoadData>
  // {
  //   return new Observable<AlchemedPreLoadData> (
  //     observer => {


  //       if (this._preLoadDatObservable)
  //       {
  //         observer.next(this._preLoadDat);
  //       }
  //       else
  //       {
  //         this._getPreLoadDataWebGuiApiCall().subscribe(
  //           res => {
  //             this._preLoadDat = res;
  //             observer.next(this._preLoadDat);
  //           }
  //         );
  //       }
  //     }
  //   );
  // }



  private getArtifactQueryParamaters (name : string, parentId : string, artifactTypeId : string, uncompressedFileSize : number, functionalCode : string = null, id : string = null) : string
  {
    const artifact : Alchemint.Artifact = new Alchemint.Artifact();
    artifact.parentId = parentId;
    artifact.patientId = parentId;
    artifact.name = name;
    artifact.artifactTypeId = artifactTypeId;
    artifact.unCompressedFileSize = uncompressedFileSize;
    artifact.functionalCode = functionalCode;
    if (id != null)
    {
      artifact.id = id;
    }

    return this.jsonSerialize(artifact);
  }

  private HandleError (err : any) : Observable<never>
  {
    if (err?.statusText)
    {
      console.log(err.statusText);
    }
    else
    {
      console.log("Missing error status");
      console.log(err);
    }

    if (err?.status == 400)
    {
      return this.HandleBadRequestError(err);
    }

    //alert(msg);
    return throwError(err);    //Rethrow it back to component
  }

  private HandleBadRequestError (err : any) : Observable<never>
  {
    if (err?.error)
    {
      return throwError(err?.error);
    }
    else
    {
      return throwError(err);
    }
  }


  private queryParamatersStringFromEntity <T> (entity : T) : string
  {
    return this.jsonSerialize(entity);
  }

  public jsonSerialize = function(obj : any) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {

        var val : string = encodeURIComponent(obj[p]);
        if (val !== 'null')
        {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
        else
        {
          // console.log('ommit');
        }

      }
    return str.join("&");
  }

  public jsonSerializeArtifact = function(obj : any) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        if ((p != "missing") && (p != "modDate") && (p != "eventDate") && (p != "compressedFileSize"))
        {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }

      }
    return str.join("&");
  }

  getFileDownloadFormatIdentifier (fileExtension : string) : any
  {
    var format : string;
    if (['.txt', '.rtf', '.htm', '.html','.csv', '.log', '.json', '.md', '.msg', '.eml'].includes(fileExtension))
      format = 'text';
    else
      format = 'uint8array';
    return format;
  }

  public ageFromDateOfBirthday(dateOfBirth: any): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }




  private _usedGuids: Array<string> = [];
  public generateGuid(ensureNoUsed : boolean) : string {
    var guid : string =  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

    if (ensureNoUsed)
    {
      if (this._usedGuids.indexOf(guid) > -1)
      {
          guid = this.generateGuid(ensureNoUsed);
      }
      else
      {
        this._usedGuids.push(guid);
      }
    }
    return guid;
   }


   public stringifyObject (obj : any) : string
   {
     if (obj)
     {
       return JSON.stringify(obj, null, 4);
     }
     else
     {
       return null;
     }
   }

   public postInvoice (accCustomerInvoice : AccCustomerInvoice): Observable<AccCustomerInvoice>
   {
    var currDate : Date = new Date();
    var timeZoneHoursOffset : number = Math.round(((-1) *  (currDate.getTimezoneOffset() / 60)));
    // console.log(this.stringifyObject(accCustomerInvoice))
    const queryStirng = this.apiUri + `accounting/postinvoice/${timeZoneHoursOffset}`;
    return this.http.post<AccCustomerInvoice>(queryStirng, accCustomerInvoice)
    .pipe(catchError((err) => { return this.HandleError(err);}));
   }

   public postPayment (accCustomerPaymentDTO : AccCustomerPaymentDTO): Observable<AccCustomerPaymentDTO>
   {
    var currDate : Date = new Date();
    var timeZoneHoursOffset : number = Math.round(((-1) *  (currDate.getTimezoneOffset() / 60)));

    const queryStirng = this.apiUri + `accounting/postpayment/${timeZoneHoursOffset}`;
    return this.http.post<AccCustomerPaymentDTO>(queryStirng, accCustomerPaymentDTO)
    .pipe(catchError((err) => { return this.HandleError(err);}));
   }


   public postCreditNote (accCustomerCreditNote : Alchemint.AccCustomerCreditNote): Observable<Alchemint.AccCustomerCreditNote>
   {
    var currDate : Date = new Date();
    var timeZoneHoursOffset : number = Math.round(((-1) *  (currDate.getTimezoneOffset() / 60)));

    const queryStirng = this.apiUri + `accounting/postcreditnote/${timeZoneHoursOffset}`;
    return this.http.post<Alchemint.AccCustomerCreditNote>(queryStirng, accCustomerCreditNote)
    .pipe(catchError((err) => { return this.HandleError(err);}));
   }





   public allocatePaymentAmounts (paymentAmountAgainstInvoiceAllocatorDTO : PaymentAmountAgainstInvoiceAllocatorDTO[]): Observable<PaymentAmountAgainstInvoiceAllocatorDTO[]>
   {
    const queryStirng = this.apiUri + `accounting/allocatepayments`;
    return this.http.post<PaymentAmountAgainstInvoiceAllocatorDTO[]>(queryStirng, paymentAmountAgainstInvoiceAllocatorDTO)
    .pipe(catchError((err) => { return this.HandleError(err);}));
   }


   public getInvoices (customerAccoiuntId : string): Observable<AccCustomerInvoice[]>
   {
    const queryStirng = this.apiUri + `accounting/getinvoices/${customerAccoiuntId}`;
    return this.http.get<AccCustomerInvoice[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
   }

   public  getPaymentsForDateRange(fromDate, toDate) : Observable<AccCustomerPaymentDTO[]>
   {
    const queryStirng = this.apiUri + `accounting/getpaymentsforrange/${fromDate}/${toDate}`;
    return this.http.get<AccCustomerPaymentDTO[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
   }

   public getPayments (customerAccoiuntId : string): Observable<AccCustomerPaymentDTO[]>
   {
    const queryStirng = this.apiUri + `accounting/getpayments/${customerAccoiuntId}`;
    return this.http.get<AccCustomerPaymentDTO[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
   }

   public  getInvoicesForDateRange(fromDate, toDate) : Observable<AccCustomerInvoice[]>
   {
    const queryStirng = this.apiUri + `accounting/getinvoicesforrange/${fromDate}/${toDate}`;
    return this.http.get<AccCustomerInvoice[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
   }

  //  public getdraftinvoices (): Observable<AccCustomerInvoice[]>
  //  {
  //   const queryStirng = this.apiUri + `accounting/getdraftinvoices`;
  //   return this.http.get<AccCustomerInvoice[]>(queryStirng)
  //     .pipe(catchError((err) => { return this.HandleError(err);}));
  //  }


   public postBillingRequestWebGuiApiCall (requestDetails : string): Observable<any>
   {
     var hds = {
       Accept: 'application/json',
       "Content-Type": 'application/json'
     };

     var queryStirng =  this.apiExtendedUrl + 'webuserinterfacebilling' + '/requestbillconsult/' + requestDetails;

     return this.http.post<any>(queryStirng, { headers : hds })
       .pipe(catchError(
         (err) => {
            return this.HandleError(err);
           }));
   }

   public getPatientHistoryWebGuiApiCall (patientId : string): Observable<PatientHistory>
   {
     var hds = {
       Accept: 'application/json'
     };
     var queryStirng =  this.apiExtendedUrl + 'webuserinterface' + '/patienthistory/' + patientId;
     var ret$ = this.http.get<PatientHistory>(queryStirng, { headers : hds });
     return ret$;
   }

   public getArtiFormState (artiFormId : string): Observable<Alchemint.ArtiFormState>
   {
     var hds = {
       Accept: 'application/json'
     };
     var queryStirng =  this.apiExtendedUrl + 'artiformmanagement/artiformstate/' + artiFormId;
     var ret$ = this.http.get<Alchemint.ArtiFormState>(queryStirng, { headers : hds });
     return ret$;

    }


   public getArtiFormStateForApiKey (artiFormId : string, apiKey: string): Observable<Alchemint.ArtiFormState>
   {

     var hds = {
       Accept: 'application/json'
     };
     var queryStirng =  this.apiExtendedUrl + `backofficeclient/artiformstateforapikey/${apiKey}/${artiFormId}`;
     var ret$ = this.http.get<Alchemint.ArtiFormState>(queryStirng, { headers : hds });
     return ret$;
   }

   public updateClientLicense  (apiKey: Alchemint.ApiKeys): Observable<Alchemint.ApiKeys>
   {
     var hds = {
       Accept: 'application/json'
     };
     var queryStirng =  this.apiExtendedUrl + `backofficeclient/updateclientlicense`;
     var ret$ = this.http.put<Alchemint.ApiKeys>(queryStirng, apiKey, { headers : hds });
     return ret$;
   }

   public addClientLicense  (apiKey: Alchemint.ApiKeys): Observable<ApiKeyWithMasterPassword>
   {
     var hds = {
       Accept: 'application/json'
     };
     var queryStirng =  this.apiExtendedUrl + `backofficeclient/addclientlicense`;
     var ret$ = this.http.put<ApiKeyWithMasterPassword>(queryStirng, apiKey, { headers : hds });
     return ret$;
   }

   

   


   public executeBackOfficereport (reportName: string, year: number, month: number, reseller: string, vendorCode: string): Observable<ReportDataDTO>
   {
     var hds = {
       Accept: 'application/json'
     };
     
     
     if ((reseller?.length > 0) && (vendorCode?.length > 0))
     {
      var queryStirng =  this.apiExtendedUrl + `backofficeclient/executebackofficereportforreseller/${reportName}/${vendorCode}/${reseller}/${year}/${month}`;


     }
     else if (reseller?.length > 0)
     {
      var queryStirng =  this.apiExtendedUrl + `backofficeclient/executebackofficereportforreseller/${reportName}/${reseller}/${year}/${month}`;
     }
     else
     {
      var queryStirng =  this.apiExtendedUrl + `backofficeclient/executebackofficereport/${reportName}/${year}/${month}`;
     }
     
     var ret$ = this.http.get<ReportDataDTO>(queryStirng, { headers : hds }).pipe(catchError((err) => { return this.HandleError(err);}));
     return ret$;
   }

   public runVsi (year: number, month: number, suportDeskUserClaimsToken: string): Observable<ReportDataDTO>
   {
     var hds = {
       Accept: 'application/json'
     };

     var encodedClaimsToken = encodeURIComponent(suportDeskUserClaimsToken);


     var queryStirng =  this.apiExtendedUrl + `backofficeclient/runvsi/${year}/${month}/${encodedClaimsToken}`;
     var ret$ = this.http.get<ReportDataDTO>(queryStirng, { headers : hds }).pipe(catchError((err) => { return this.HandleError(err);}));
     return ret$;
   }

   public runVsiForClient (year: number, month: number, clientId: string): Observable<ReportDataDTO>
   {
     var hds = {
       Accept: 'application/json'
     };
     var queryStirng =  this.apiExtendedUrl + `backofficeclient/runvsiforclient/${year}/${month}/${clientId}`;
     var ret$ = this.http.get<ReportDataDTO>(queryStirng, { headers : hds }).pipe(catchError((err) => { return this.HandleError(err);}));
     return ret$;
   }

   public runbackofficereportforclient (reportName: string, year: number, month: number, clientId: string, encodedToken: string): Observable<ReportDataDTO>
   {
     var hds = {
       Accept: 'application/json'
     };
     var queryStirng =  this.apiExtendedUrl + `backofficeclient/runbackofficereportforclient/${reportName}/${year}/${month}/${clientId}/${encodedToken}`;
     var ret$ = this.http.get<ReportDataDTO>(queryStirng, { headers : hds }).pipe(catchError((err) => { return this.HandleError(err);}));
     return ret$;
   }

   public runVsiForClientForPastYear (clientId: string, encodedToken: string): Observable<ReportDataDTO>
   {
     var hds = {
       Accept: 'application/json'
     };
     var queryStirng =  this.apiExtendedUrl + `backofficeclient/runvsiforclientforlastyear/${clientId}/${encodedToken}`;
     var ret$ = this.http.get<ReportDataDTO>(queryStirng, { headers : hds }).pipe(catchError((err) => { return this.HandleError(err);}));
     return ret$;
   }
   
   public runTrainingProgressForClient (clientId: string, encodedToken: string): Observable<ReportDataDTO>
   {

     var hds = {
       Accept: 'application/json'
     };
     var queryStirng =  this.apiExtendedUrl + `backofficeclient/runtrainingprogressforclient/${clientId}/${encodedToken}`;
     var ret$ = this.http.get<ReportDataDTO>(queryStirng, { headers : hds }).pipe(catchError((err) => { return this.HandleError(err);}));
     return ret$;
   }



//

   public attachInvoiceFile (invoiceHeadId : string,patientId : string, pdffile : File): Observable<Alchemint.Artifact>
   {
     var queryString =  this.apiExtendedUrl + `webuserinterfacebilling/attachinvoicefile/${invoiceHeadId}/${patientId}`;
     const formData = new FormData();
     formData.append('file', pdffile);
     return this.http.post<any>(queryString, formData)
       .pipe(catchError((err) => { return this.HandleError(err);}));
   }



   public uploadTemplateFile (file : File, templateDocumentFeatureCode:  TemplateDocumentFeatureCode = null): Observable<Alchemint.Artifact>
   {
     var queryString =  this.apiExtendedUrl + `setup/addtemplate`;

     if (templateDocumentFeatureCode != null)
     {
      queryString = `${queryString}/${templateDocumentFeatureCode}`;
     }

     const formData = new FormData();
     formData.append('file', file);
     return this.http.post<any>(queryString, formData)
       .pipe(catchError((err) => { return this.HandleError(err);}));
   }


   public importArtiFormFile (file : File): Observable<ArtiFormBundle>
   {
     var queryString =  this.apiExtendedUrl + `artiformmanagement/importform`;
     const formData = new FormData();
     formData.append('file', file);
     return this.http.post<any>(queryString, formData)
       .pipe(catchError((err) => { return this.HandleError(err);}));
   }

   public importCustomListFile (file : File): Observable<CustomListExportDTO>
   {
     var queryString =  this.apiExtendedUrl + `customlists/importcustomlist`;
     const formData = new FormData();
     formData.append('file', file);
     return this.http.post<any>(queryString, formData)
       .pipe(catchError((err) => { return this.HandleError(err);}));
   }

   patientVarObs$ = this.http.get<string[]>(this.apiExtendedUrl + `artiformmanagement/getpatientvariables`).pipe(shareReplay());

   public getpatientvariables (): Observable<string[]>
   {
    return this.patientVarObs$;
    // var queryString =  this.apiExtendedUrl + `artiformmanagement/getpatientvariables`;
    //  return this.http.get<string[]>(queryString).pipe(share());
   }


   templateIncludesObs$ = this.http.get<string[]>(this.apiExtendedUrl + `artiformmanagement/gettemplateincludecodes`);

   public gettempateincludevariables (): Observable<string[]>
   {
    return this.templateIncludesObs$;
   }




   public getformsthatuseatemplate (templateArtiFactId : string): Observable<Alchemint.ArtiForm[]>
   {
    var queryString =  this.apiExtendedUrl + `artiformmanagement/getformsthatuseatemplate/${templateArtiFactId}`;
    return this.http.get<Alchemint.ArtiForm[]>(queryString).pipe();
   }

   public updateartiform (artiFormBundle : ArtiFormBundle): Observable<any>
   {
    var queryString =  this.apiExtendedUrl + `artiformmanagement/updateartiform`;
    return this.http.post<any>(queryString, artiFormBundle, { headers : this.buildHeadsAppJson() });
   }


   public moveArtifact (moveArtifacte : Alchemint.Artifact, targetArtifactId : string): Observable<Alchemint.Artifact>
   {
    var queryString =  this.apiExtendedUrl + `artiformmanagement/moveartifact/${targetArtifactId}`;
    return this.http.put<Alchemint.Artifact>(queryString, moveArtifacte, { headers : this.buildHeadsAppJson() });
   }

   public moveArtifactToPatient (moveArtifacte : Alchemint.Artifact, patientId: string, targetArtifactId : string): Observable<Alchemint.Artifact>
   {
    var queryString =  this.apiExtendedUrl + `artiformmanagement/moveartifactopatient/${patientId}/${targetArtifactId}`;
    return this.http.put<Alchemint.Artifact>(queryString, moveArtifacte, { headers : this.buildHeadsAppJson() });
   }

   public moveArtiformInstanceToPatient(moveArtiformInstance: ArtiFormInstanceWithArtifactPreview, patientId: string, targetArtifactId: string): Observable<Alchemint.ArtiFormInstance> {
    var queryString =  this.apiExtendedUrl + `artiformmanagement/moveartiforminstanceopatient/${patientId}/${targetArtifactId}`;
    return this.http.put<Alchemint.ArtiFormInstance>(queryString, moveArtiformInstance.artiformInstance, { headers : this.buildHeadsAppJson() });
   }




   public getformstructurehash (artiFormId: string): Observable<TextDTO>
   {
    var queryString =  this.apiExtendedUrl + `artiformmanagement/getformstructurehash/${artiFormId}`;
    return this.http.get<TextDTO>(queryString).pipe();
   }


   public getArtifactAsString (artiFactId: string): Observable<TextDTO>
   {
    var queryString =  this.apiExtendedUrl + `artifactmanagement/getartifactasstring/${artiFactId}`;
    return this.http.get<TextDTO>(queryString).pipe();
   }

  //  public getArtifactFileUnzipped (artiFactId: string): Observable<Uint8Array>
  //  {
  //   var queryString =  this.apiExtendedUrl + `artifactmanagement/getartifactunzipped/${artiFactId}`;
  //   return this.http.get<Uint8Array>(queryString).pipe();
  //  }

   public getArtifactAsBase64image(artiFactId: string): Observable<TextDTO> {

    var queryString =  this.apiExtendedUrl + `artifactmanagement/getartifactasbase64image/${artiFactId}`;
    return this.http.get<TextDTO>(queryString).pipe();
  }


  public getSupportReplayArtifact(artiFactId: string): Observable<TextDTO> {

    var queryString =  this.apiExtendedUrl + `artifactmanagement/getsupportreplayartifact/${artiFactId}`;
    return this.http.get<TextDTO>(queryString).pipe();
  }

  

  public getArtifactSyncDtos(): Observable<ArtifactSyncDTO[]> {

    var queryString =  this.apiExtendedUrl + `backupmanagement/getartifactsyncdtos`;
    return this.http.get<ArtifactSyncDTO[]>(queryString).pipe();
  }

  public getMainDbfile(): Observable<ArrayBuffer> {

    var queryString =  this.apiExtendedUrl + `backupmanagement/getmaindbfile`;
    return this.http.get(queryString, {responseType : 'arraybuffer'})
    .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  public getArtiFormBundleWebGuiApiCall(artiFormId: string, artiFormInstanceId: string, patientId: string, includeTemplate: boolean): Observable<ArtiFormBundle> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng: string;
    if (includeTemplate) {
      queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/';
    }
    else {
      queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/getartiformbundlewithtemplateifsmartform/';

    }

    queryStirng += encodeURIComponent(artiFormId) + '/';
    queryStirng += encodeURIComponent(artiFormInstanceId) + '/';
    queryStirng += encodeURIComponent(patientId);

    var ret$ = this.http.get<ArtiFormBundle>(queryStirng, { headers: hds });

    return ret$;

  }

   public updatreartifactwithtextdata (artiFactId: string, data: string): Observable<Alchemint.Artifact>
   {

    var queryString =  this.apiExtendedUrl + `artifactmanagement/updatreartifactwithtextdata/${artiFactId}`;
    var textDto: TextDTO = new TextDTO();
    textDto.text = data;
    return this.http.post<Alchemint.Artifact>(queryString, textDto).pipe();
   }

   public createArtifactWithTextdata (patientId: string, name: string, artifactCode: string, data: string): Observable<Alchemint.Artifact>
   {
    var queryString =  this.apiExtendedUrl + `artifactmanagement/createartifactwithtextdata/${patientId}/${artifactCode}/${encodeURIComponent(name)}`;
    var textDto: TextDTO = new TextDTO();
    textDto.text = data;
    return this.http.post<Alchemint.Artifact>(queryString, textDto).pipe();
   }

   public createArtifactWithTextdataForSpecificApiKey (apiKey: string, patientId: string, name: string, artifactCode: string, data: string): Observable<Alchemint.Artifact>
   {
    var queryString =  this.apiExtendedUrl + `artifactmanagement/createartifactwithtextdataforspecificapikey/${apiKey}/${patientId}/${artifactCode}/${encodeURIComponent(name)}`;
    var textDto: TextDTO = new TextDTO();
    textDto.text = data;
    return this.http.post<Alchemint.Artifact>(queryString, textDto).pipe();
   }

   public createBlankHtmlClinicalNotes (patientId: string): Observable<Alchemint.Artifact>
   {
    var queryString =  this.apiExtendedUrl + `artifactmanagement/createblankhtmlclinicalnotes/${patientId}`;
    return this.http.post<Alchemint.Artifact>(queryString, null).pipe();
   }
   

   public appendSummaryToPatientClinicalNotes(patientId: string, formName: string, summary: string): Observable<ArtifactAndIsNewDTO> {
    
    var textDTO: TextDTO = new TextDTO();
    textDTO.text = summary;
    var queryString =  this.apiExtendedUrl + `artifactmanagement/appendtoclinicalnotes/${patientId}/${formName}`;
    return this.http.put<ArtifactAndIsNewDTO>(queryString, textDTO).pipe();
   }


   public converttxtclinicalnotestohtm (artifactId: string): Observable<Alchemint.Artifact>
   {
    var queryString =  this.apiExtendedUrl + `artifactmanagement/converttxtclinicalnotestohtm/${artifactId}`;
    return this.http.post<Alchemint.Artifact>(queryString, null).pipe();
   }


  //  public querychatgpt (prompt : string): Observable<string>
  //  {
  //   var queryString =  this.apiExtendedUrl + `artiformmanagement/querychatgpt/${prompt}`;
  //   return this.http.get<string>(queryString).pipe();
  //  }
   public createartiform (artiFormBundle : ArtiFormBundle): Observable<string>
   {
    var queryString =  this.apiExtendedUrl + `artiformmanagement/createartiform`;
    return this.http.post<string>(queryString, artiFormBundle, { headers : this.buildHeadsAppJson() });
   }
   public querychatgptgenerally(prompt: string, useCache: boolean): Observable<string> {
    // Ensure the prompt is encoded before constructing the query string
    const encodedPrompt = encodeURIComponent(prompt);
    const queryString = `${this.apiExtendedUrl}ai/querychatgptgenerally/${encodedPrompt}/${useCache}`;
    return this.http.get<string>(queryString, { headers: this.buildHeadsAppJson() });
  }

   public querychatgptgenerallywithvariables (prompt : string, eHealthCheckItemType: eHealthCheckItemType, variables : [string,string][], useCache: boolean): Observable<string>
   {
    const encodedPrompt = encodeURIComponent(prompt);
    var queryString =  this.apiExtendedUrl + `ai/querychatgptgenerallywithvars/${encodedPrompt}/${eHealthCheckItemType}/${JSON.stringify(variables)}/${useCache}`;
    return this.http.get<string>(queryString,  { headers : this.buildHeadsAppJson() });
   }




   aiGetspecialitycodes() : Observable<Alchemint.MedicalSpeciality[]>
   {
    var queryString =  this.apiExtendedUrl + `ai/getspecialitycodes`;
    return this.http.get<Alchemint.MedicalSpeciality[]>(queryString,  { headers : this.buildHeadsAppJson() });
   }


   public aiGenerateForm (about : string, numberOfQuestions : string, questLanguage : string): Observable<ChatGPTQuestionairreQuestion[]>
   {
    var queryString =  this.apiExtendedUrl + `ai/generatequestionnaire/${about}/${numberOfQuestions}/${questLanguage}`;
    return this.http.get<ChatGPTQuestionairreQuestion[]>(queryString,  { headers : this.buildHeadsAppJson() });
   }

   public aiGenerateFormForMeaningFulQuestionnaireNAme (meaningfulname : string, numberOfQuestions : string, questLanguage : string): Observable<ChatGPTQuestionairreQuestion[]>
   {
    var queryString =  this.apiExtendedUrl + `ai/generatenamedquestionnaire/${meaningfulname}/${numberOfQuestions}/${questLanguage}`;
    return this.http.get<ChatGPTQuestionairreQuestion[]>(queryString,  { headers : this.buildHeadsAppJson() });
   }


  //  public aiGenerateNamedKnownQuestionnaireNAme (meaningfulname : string, questLanguage : string): Observable<ChatGPTQuestionairreQuestion[]>
  //  {
  //   var queryString =  this.apiExtendedUrl + `ai/generatenamedquestionnaire/${meaningfulname}/${questLanguage}`;
  //   return this.http.get<ChatGPTQuestionairreQuestion[]>(queryString,  { headers : this.buildHeadsAppJson() });
  //  }

   public aiGeneratekNOWNQuestionnaireNAme (meaningfulname : string, questLanguage : string): Observable<ChatGPTQuestionairreQuestion[]>
   {
    var queryString =  this.apiExtendedUrl + `ai/generateknownform/${meaningfulname}/${questLanguage}`;
    return this.http.get<ChatGPTQuestionairreQuestion[]>(queryString,  { headers : this.buildHeadsAppJson() });
   }


   public aiGeneratekPhysioExcercise (excerciseNAme : string, questLanguage : string): Observable<ExerciseDetail>
   {
    var queryString =  this.apiExtendedUrl + `ai/generatephysioexcercise/${excerciseNAme}/${questLanguage}`;
    return this.http.get<ExerciseDetail>(queryString,  { headers : this.buildHeadsAppJson() });
   }



   public generateOperationFormForOperationType (operationName : string, questLanguage : string): Observable<ChatGPTOperationFormDTO>
   {
    var queryString =  this.apiExtendedUrl + `ai/generateoperationformforoperationtype/${operationName}/${questLanguage}`;
    return this.http.get<ChatGPTOperationFormDTO>(queryString,  { headers : this.buildHeadsAppJson() });
   }




   public aiGenerateFormForContentSupplied (formcontent : StringDTO): Observable<ChatGPTQuestionnaireDTO>
   {
    var queryString =  this.apiExtendedUrl + `ai/generatequestionnaireforcontentssupplied`;
    return this.http.post<ChatGPTQuestionnaireDTO>(queryString,formcontent, { headers : this.buildHeadsAppJson() });
   }


   public imagetotext (file : File, patientId : string, artifactTypeId : string): Observable<TextDTO>
   {
    const formData = new FormData();
    formData.append('file', file);
    //var queryString =  this.apiExtendedUrl + `ai/imagetotext/${patientId}/${artifactTypeId}`;
    var queryString =  this.apiExtendedUrl + `ai/imagetotext`;
    return this.http.post<TextDTO>(queryString, formData).pipe(catchError((err) => { return this.HandleError(err);}));;
   }



   public imagetotextusingopenai (file : File, patientId : string, artifactTypeId : string): Observable<TextDTO>
   {
    const formData = new FormData();
    formData.append('file', file);
    //var queryString =  this.apiExtendedUrl + `ai/imagetotext/${patientId}/${artifactTypeId}`;
    var queryString =  this.apiExtendedUrl + `ai/imagetotextusingopenai`;
    return this.http.post<TextDTO>(queryString, formData).pipe(catchError((err) => { return this.HandleError(err);}));;
   }

  //  public imagetoPatientRecordUsingOpenAi (file : File): Observable<TextDTO>
  //  {
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   var queryString =  this.apiExtendedUrl + `ai/imagetopatientrecordusingopenai`;
  //   return this.http.post<TextDTO>(queryString, formData).pipe(catchError((err) => { return this.HandleError(err);}));;
  //  }


   public imagetoPatientRecordUsingOpenAi(file: File): Observable<TextDTO> {
    const formData = new FormData();
    formData.append('file', file);
    const queryString = this.apiExtendedUrl + `ai/imagetopatientrecordusingopenai`;
  
    // Emit an event indicating the start of the image to patient record process
    this.aiEventSubject.next('Image to patient record using OpenAI started');
  
    return this.http.post<TextDTO>(queryString, formData).pipe(
      tap(() => {
        // Emit an event indicating the image to patient record process has finished successfully
        this.aiEventSubjectFinished.next('Image to patient record using OpenAI finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the image to patient record process has finished with an error
        this.aiEventSubjectFinished.next('Image to patient record using OpenAI error');
        return this.HandleError(err);
      })
    );
  }
  


  public imagetoOperationNoteUsingOpenAi(artiFact: Alchemint.Artifact, loginID: string): Observable<TextDTO> {
    const queryString = this.apiExtendedUrl + `ai/imagetoperationnotestext/${artiFact.id}/${artiFact.patientId}/${loginID}`;
  
    // Emit an event indicating the start of the image to patient record process
    this.aiEventSubject.next('Image to clinical notes  using OpenAI started');
  
    return this.http.post<TextDTO>(queryString, null).pipe(
      tap(() => {
        // Emit an event indicating the image to patient record process has finished successfully
        this.aiEventSubjectFinished.next('Image to clinical notes using OpenAI finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the image to patient record process has finished with an error
        this.aiEventSubjectFinished.next('Image to clinical notesrecord using OpenAI error');
        return this.HandleError(err);
      })
    );
  }

  public imagetoOperationFormUsingOpenAi(artiFact: Alchemint.Artifact, loginID: string, relatedConsultId: string, surgicalEvent: Alchemint.SurgicalEventDetails): Observable<Alchemint.ArtiFormInstance> {
    const queryString = this.apiExtendedUrl + `ai/imagetoperationform/${artiFact.id}/${artiFact.patientId}/${loginID}/${relatedConsultId}`;
  
    // Emit an event indicating the start of the image to patient record process
    this.aiEventSubject.next('Image to Op Form  using OpenAI started');
  
    return this.http.post<Alchemint.ArtiFormInstance>(queryString, surgicalEvent).pipe(
      tap(() => {
        // Emit an event indicating the image to patient record process has finished successfully
        this.aiEventSubjectFinished.next('Image to Op Form  using OpenAI finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the image to patient record process has finished with an error
        this.aiEventSubjectFinished.next('Image to Op Form using OpenAI error');
        return this.HandleError(err);
      })
    );
  }

   public imagetopatientinfo(file: File, patientId: string, artifactTypeId: string): Observable<PatientFromScanStickerDTO> {
    const formData = new FormData();
    formData.append('file', file);
    const queryString = this.apiExtendedUrl + `ai/imagetopatientinfo`;

    // Emit an event indicating the start of the image to patient info process
    this.aiEventSubject.next('Image to patient info started');

    return this.http.post<PatientFromScanStickerDTO>(queryString, formData).pipe(
      tap(() => {
        // Emit an event indicating the image to patient info process has finished successfully
        this.aiEventSubjectFinished.next('Image to patient info finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the image to patient info process has finished with an error
        this.aiEventSubjectFinished.next('Image to patient info error');
        return this.HandleError(err);
      })
    );
  }

  public imagetAppScreenSpec(file: File): Observable<TextDTO> {
    const formData = new FormData();
    formData.append('file', file);
    const queryString = this.apiExtendedUrl + `ai/screenshottohtmlcomponent`;

    return this.http.post<TextDTO>(queryString, formData).pipe(catchError((err) => { return this.HandleError(err);}));;
  }
  
  public imagetoAppDocumentation(file: File): Observable<TextDTO> {
    const formData = new FormData();
    formData.append('file', file);
    const queryString = this.apiExtendedUrl + `ai/screenshottusagedocumentation`;

    return this.http.post<TextDTO>(queryString, formData).pipe(catchError((err) => { return this.HandleError(err);}));;
  }
  

   public extractTextFromImageArtiFact (artifact : Alchemint.Artifact): Observable<TextDTO>
   {
    //var queryString =  this.apiExtendedUrl + `ai/imagetotext/${patientId}/${artifactTypeId}`;
    var queryString =  this.apiExtendedUrl + `ai/extracttextfromimageartiFact/${artifact.id}`;
    return this.http.get<TextDTO>(queryString).pipe(catchError((err) => { return this.HandleError(err);}));;
   }

   public extractICD10ProbablesForRadiologyReport (artifact : Alchemint.Artifact): Observable<TextDTO>
   {
    //var queryString =  this.apiExtendedUrl + `ai/imagetotext/${patientId}/${artifactTypeId}`;
    var queryString =  this.apiExtendedUrl + `ai/extracticd10probablesforradiologyreport/${artifact.id}`;
    return this.http.get<TextDTO>(queryString).pipe(catchError((err) => { return this.HandleError(err);}));;
   }


   


  //  public uploadFileForTranscription (file : File, patientId : string, artifactTypeId : string): Observable<Alchemint.Artifact>
  //  {
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   var queryString =  this.apiExtendedUrl + `ai/transcribeaudio/${patientId}/${artifactTypeId}`;
  //   return this.http.post<Alchemint.Artifact>(queryString, formData).pipe(catchError((err) => { return this.HandleError(err);}));;
  //  }


   public uploadFileForTranscription(file: File, patientId: string, artifactTypeId: string): Observable<Alchemint.Artifact> {
    const formData = new FormData();
    formData.append('file', file);
    const queryString = this.apiExtendedUrl + `ai/transcribeaudio/${patientId}/${artifactTypeId}`;

    // Emit an event indicating the start of the transcription process
    this.aiEventSubject.next('Transcription started');

    return this.http.post<Alchemint.Artifact>(queryString, formData).pipe(
      tap(() => {
        // Emit an event indicating the transcription process has finished successfully
        this.aiEventSubjectFinished.next('Transcription finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the transcription process has finished with an error
        this.aiEventSubjectFinished.next('Transcription error');
        return this.HandleError(err);
      })
    );
  }


  //  public uploadFileForTranscriptionToString (file : File, batchId: string, seconds: number, languageCode: string): Observable<WhisperDTO>
  //  {
  //   const formData = new FormData();
  //   formData.append('file', file);

  //   if (!languageCode)
  //   {
  //     languageCode = 'en';
  //   }
  //   var queryString =  this.apiExtendedUrl + `ai/transcribeaudiotostring/${batchId}/${seconds}/${languageCode}`;
  //   return this.http.post<WhisperDTO>(queryString, formData).pipe(catchError((err) => { return this.HandleError(err);}));;
  //  }
  public uploadFileForTranscriptionToString(file: File, batchId: string, seconds: number, languageCode: string = 'en'): Observable<WhisperDTO> {
    const formData = new FormData();
    formData.append('file', file);
  
    const queryString = this.apiExtendedUrl + `ai/transcribeaudiotostring/${batchId}/${seconds}/${languageCode}`;
  
    // Emit an event indicating the start of the transcription to string process
    this.aiEventSubject.next('Transcription to string started');
  
    return this.http.post<WhisperDTO>(queryString, formData).pipe(
      tap(() => {
        // Emit an event indicating the transcription to string process has finished successfully
        this.aiEventSubjectFinished.next('Transcription to string finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the transcription to string process has finished with an error
        this.aiEventSubjectFinished.next('Transcription to string error');
        return this.HandleError(err);
      })
    );
  }
  
   async uploadLargeFileForTranscriptionInChunksToString(file: File, languageCode: string) : Promise<WhisperDTO>
   {
    const totalChunks = Math.ceil(file.size / this.chunkSize);
    var finalResult: WhisperDTO = null;
    for (let i = 0; i < totalChunks; i++) {
      const start = i * this.chunkSize;
      const end = Math.min(start + this.chunkSize, file.size);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('fileName', file.name);
      formData.append('chunkIndex', i.toString());
      formData.append('totalChunks', totalChunks.toString());
      formData.append('languageCode', totalChunks.toString());
      
      finalResult = await firstValueFrom(this.uploadChunk(formData));
    }
    return finalResult;

  }

  uploadChunk(formData: FormData) : Observable<WhisperDTO>
  {
    var queryString =  this.apiExtendedUrl + `ai/transcribelongaudiofileinchunkstostring`;
    return this.http.post<WhisperDTO>(queryString, formData).pipe(catchError((err) => { return this.HandleError(err);}))
  }
   public translateArtiForm (artiFormId : string, fromLanguage: string, toLanguage: string): Observable<ArtiFormBundle>
   {
    var queryString =  this.apiExtendedUrl + `ai/translateform/${artiFormId}/${fromLanguage}/${toLanguage}`;
    return this.http.post<ArtiFormBundle>(queryString, null).pipe(catchError((err) => { return this.HandleError(err);}));;
   }

   public summarizeFormInstance (artiFormInstanceId: string, includePossibleDiagnosis: boolean, patientId: string): Observable<TextDTO>
   {
    var queryString =  this.apiExtendedUrl + `ai/summarizeform/${artiFormInstanceId}/${includePossibleDiagnosis}/${patientId}`;
    return this.http.post<TextDTO>(queryString, null).pipe(catchError((err) => { return this.HandleError(err);}));;
   }

   public convertTextToSpeechAudio (text : string): Observable<any>
   {

    const headers = new HttpHeaders({
      'Accept': 'application/octet-stream',
      'Content-Type': 'application/json',
    });
    var t: TextDTO = new TextDTO();
    t.text = text;
    var queryString =  this.apiExtendedUrl + `ai/texttospeech`;
    return this.http.post<any>(queryString, t, { headers: headers, responseType : 'arraybuffer' as 'json'}, ).pipe(catchError((err) => { return this.HandleError(err);}));;
  }


  // public aiStructuretextAsMarkDown (text : string): Observable<TextDTO>
  // {
  //   var te: TextDTO = new  TextDTO();
  //   te.text = text;
  //   var queryString =  this.apiExtendedUrl + `ai/structuretext`;
  //   return this.http.post<TextDTO>(queryString, te).pipe(catchError((err) => { return this.HandleError(err);}));;
  // }

  // public aiSummarize (text : string): Observable<TextDTO>
  // {
  //   var te: TextDTO = new  TextDTO();
  //   te.text = text;
  //   var queryString =  this.apiExtendedUrl + `ai/summarize`;
  //   return this.http.post<TextDTO>(queryString, te).pipe(catchError((err) => { return this.HandleError(err);}));;
  // }

  // public aiTranslate (text : string, language: string): Observable<TextDTO>
  // {
  //   var te: TextDTO = new  TextDTO();
  //   te.text = text;
  //   var queryString =  this.apiExtendedUrl + `ai/translate/${language}`;
  //   return this.http.post<TextDTO>(queryString, te).pipe(catchError((err) => { return this.HandleError(err);}));;
  // }

  // public aiTranslateTextWithinHtml (text : string, language: string): Observable<TextDTO>
  // {
  //   var te: TextDTO = new  TextDTO();
  //   te.text = text;
  //   var queryString =  this.apiExtendedUrl + `ai/translatetextwithinhtml/${language}`;
  //   return this.http.post<TextDTO>(queryString, te).pipe(catchError((err) => { return this.HandleError(err);}));;
  // }

  // public aiCreateLetterFromNoteSelection (text : string): Observable<TextDTO>
  // {
  //   var te: TextDTO = new  TextDTO();
  //   te.text = text;
  //   var queryString = this.apiExtendedUrl + `ai/createletterfromnoteselectionwithai`;
  //   return this.http.post<TextDTO>(queryString, te).pipe(catchError((err) => { return this.HandleError(err);}));;
  // }



  // public aiBreakupIntoParagraphs (text : string): Observable<TextDTO>
  // {
  //   var te: TextDTO = new  TextDTO();
  //   te.text = text;
  //   var queryString =  this.apiExtendedUrl + `ai/breakupintoparagraphs`;
  //   return this.http.post<TextDTO>(queryString, te).pipe(catchError((err) => { return this.HandleError(err);}));;
  // }


  public aiStructuretextAsMarkDown(text: string): Observable<TextDTO> {
    const te: TextDTO = new TextDTO();
    te.text = text;
    const queryString = this.apiExtendedUrl + `ai/structuretext`;
  
    // Emit an event indicating the start of the process
    this.aiEventSubject.next('Structure text as Markdown started');
  
    return this.http.post<TextDTO>(queryString, te).pipe(
      tap(() => {
        // Emit an event indicating the process has finished successfully
        this.aiEventSubjectFinished.next('Structure text as Markdown finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the process has finished with an error
        this.aiEventSubjectFinished.next('Structure text as Markdown error');
        return this.HandleError(err);
      })
    );
  }
  
  public aiSummarize(text: string): Observable<TextDTO> {
    const te: TextDTO = new TextDTO();
    te.text = text;
    const queryString = this.apiExtendedUrl + `ai/summarize`;
  
    // Emit an event indicating the start of the summarization process
    this.aiEventSubject.next('Summarization started');
  
    return this.http.post<TextDTO>(queryString, te).pipe(
      tap(() => {
        // Emit an event indicating the summarization process has finished successfully
        this.aiEventSubjectFinished.next('Summarization finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the summarization process has finished with an error
        this.aiEventSubjectFinished.next('Summarization error');
        return this.HandleError(err);
      })
    );
  }
  
  public aiTranslate(text: string, language: string): Observable<TextDTO> {
    const te: TextDTO = new TextDTO();
    te.text = text;
    const queryString = this.apiExtendedUrl + `ai/translate/${language}`;
  
    // Emit an event indicating the start of the translation process
    this.aiEventSubject.next('Translation started');
  
    return this.http.post<TextDTO>(queryString, te).pipe(
      tap(() => {
        // Emit an event indicating the translation process has finished successfully
        this.aiEventSubjectFinished.next('Translation finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the translation process has finished with an error
        this.aiEventSubjectFinished.next('Translation error');
        return this.HandleError(err);
      })
    );
  }
  
  public aiTranslateTextWithinHtml(text: string, language: string): Observable<TextDTO> {
    const te: TextDTO = new TextDTO();
    te.text = text;
    const queryString = this.apiExtendedUrl + `ai/translatetextwithinhtml/${language}`;
  
    // Emit an event indicating the start of the translation within HTML process
    this.aiEventSubject.next('Translation within HTML started');
  
    return this.http.post<TextDTO>(queryString, te).pipe(
      tap(() => {
        // Emit an event indicating the translation within HTML process has finished successfully
        this.aiEventSubjectFinished.next('Translation within HTML finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the translation within HTML process has finished with an error
        this.aiEventSubjectFinished.next('Translation within HTML error');
        return this.HandleError(err);
      })
    );
  }
  
  public aiCreateLetterFromNoteSelection(text: string): Observable<TextDTO> {
    const te: TextDTO = new TextDTO();
    te.text = text;
    const queryString = this.apiExtendedUrl + `ai/createletterfromnoteselectionwithai`;
  
    // Emit an event indicating the start of the letter creation process
    this.aiEventSubject.next('Create letter from note selection started');
  
    return this.http.post<TextDTO>(queryString, te).pipe(
      tap(() => {
        // Emit an event indicating the letter creation process has finished successfully
        this.aiEventSubjectFinished.next('Create letter from note selection finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the letter creation process has finished with an error
        this.aiEventSubjectFinished.next('Create letter from note selection error');
        return this.HandleError(err);
      })
    );
  }
  
  public aiBreakupIntoParagraphs(text: string): Observable<TextDTO> {
    const te: TextDTO = new TextDTO();
    te.text = text;
    const queryString = this.apiExtendedUrl + `ai/breakupintoparagraphs`;
  
    // Emit an event indicating the start of the breakup into paragraphs process
    this.aiEventSubject.next('Breakup into paragraphs started');
  
    return this.http.post<TextDTO>(queryString, te).pipe(
      tap(() => {
        // Emit an event indicating the breakup into paragraphs process has finished successfully
        this.aiEventSubjectFinished.next('Breakup into paragraphs finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the breakup into paragraphs process has finished with an error
        this.aiEventSubjectFinished.next('Breakup into paragraphs error');
        return this.HandleError(err);
      })
    );
  }
  
  public aiGenerateWebPageBroken(text: string): Observable<TextDTO> {
    const te: TextDTO = new TextDTO();
    te.text = text;
    const queryString = this.apiExtendedUrl + `ai/generatewebpage`;
  
    // Emit an event indicating the start of the breakup into paragraphs process
    this.aiEventSubject.next('Generate web page started');
  
    return this.http.post<TextDTO>(queryString, te).pipe(
      tap(() => {
        // Emit an event indicating the breakup into paragraphs process has finished successfully
        this.aiEventSubjectFinished.next('Generate web page  finished');
      }),
      catchError((err) => {
        // Handle the error and emit an event indicating the breakup into paragraphs process has finished with an error
        this.aiEventSubjectFinished.next('Generate web page  error');
        return this.HandleError(err);
      })
    );
  }

  
  public aiGenerateWebPage(text: string): Observable<TextDTO> {

    const te: TextDTO = new TextDTO();
    te.text = text;
    const queryString = this.apiExtendedUrl + `ai/generatewebpage`;
  

    var hds = {
      Accept: 'application/json'
    };
    var ret$ = this.http.post<TextDTO>(queryString, te, { headers: hds});
    return ret$;
  }

  public aiGenerateAngularFromHtml(htmlText: string, componentName: string ): Observable<AngularComponent> {



    const teHtml: TextDTO = new TextDTO();
    teHtml.text = htmlText;

    const teModInstruct: TextDTO = new TextDTO();
    teModInstruct.text = componentName;

    const queryString = this.apiExtendedUrl + `ai/generateangularfromhtml`;

    var hds = {
      Accept: 'application/json'
    };
    var ret$ = this.http.post<AngularComponent>(queryString, [teHtml, teModInstruct], { headers: hds});
    return ret$;

  }

  public aiGenerateAngularFromSpec(spec: string, componentName: string ): Observable<AngularComponent> {
    const te: TextDTO = new TextDTO();
    te.text = spec;

    const teModInstruct: TextDTO = new TextDTO();
    teModInstruct.text = componentName;

    const queryString = this.apiExtendedUrl + `ai/generateangularfromspec`;

    var hds = { Accept: 'application/json' };
    var ret$ = this.http.post<AngularComponent>(queryString, [te, teModInstruct], { headers: hds});
    return ret$;
  }

  
  
  public aiCodeReview(component: AngularComponent): Observable<TextDTO> {
    const teComponent: TextDTO = new TextDTO();
    teComponent.text = component.componentFile;

    const teHtml: TextDTO = new TextDTO();
    teHtml.text = component.htmlTemplateFile;
    
    const tePrompt: TextDTO = new TextDTO();
    tePrompt.text = 'Please review this code. Then once you have reviewed spec out detailed instructions for the developers to action based on the review. The spec should be formatted in Markdown.';


    const queryString = this.apiExtendedUrl + `ai/performpromtforcomponent`;

    var hds = { Accept: 'application/json' };
    var ret$ = this.http.post<TextDTO>(queryString, [teHtml, teComponent, tePrompt], { headers: hds});
    return ret$;
  }

  public aiCodeReviewAndGetChangeInstructions(component: AngularComponent): Observable<DeveloperInstruction[]> {
    const teComponent: TextDTO = new TextDTO();
    teComponent.text = component.componentFile;

    const teHtml: TextDTO = new TextDTO();
    teHtml.text = component.htmlTemplateFile;
    
    const tePrompt: TextDTO = new TextDTO();
    tePrompt.text = 'Please review this code. Then once you have reviewed spec out detailed instructions for the developers for the the 5 most important items to action based on the review. Return the individual instructions of these items in a json array of objects that can deserialize to this class: public class DeveloperInstruction   {  public string Instruction { get; set; }  }.';


    const queryString = this.apiExtendedUrl + `ai/codereviewandgetchangeinstructions`;

    var hds = { Accept: 'application/json' };
    var ret$ = this.http.post<DeveloperInstruction[]>(queryString, [teHtml, teComponent, tePrompt], { headers: hds});
    return ret$;
  }
  

  public aiGetCodeChangeInstructions(component: AngularComponent, devInstruction: DeveloperInstruction): Observable<CodeModifyInstruction[]> {
    const teComponent: TextDTO = new TextDTO();
    teComponent.text = component.componentFile;

    const teHtml: TextDTO = new TextDTO();
    teHtml.text = component.htmlTemplateFile;
    
    const tePrompt: TextDTO = new TextDTO();



    const teFileName: TextDTO = new TextDTO();
    
    if (component.componentFileName)
    {
      teFileName.text = component.componentFileName;
    }
    else if (component.htmlTemplateFileName)
    {
      teFileName.text = component.htmlTemplateFileName;
    }
    else if (component.scssFileName)
    {
      teFileName.text = component.scssFileName;
    }
    

    var codeModifyInstruction: CodeModifyInstruction = new CodeModifyInstruction();


    codeModifyInstruction.directory = "C:/temp/";
    codeModifyInstruction.fileName = "";
    codeModifyInstruction.removeLineStart = 0;
    codeModifyInstruction.removeLineEnd = 0;
    codeModifyInstruction.insertionLine = 0;
    codeModifyInstruction.insertionCode = "";


    var json = JSON.stringify(codeModifyInstruction, null, 2);

    tePrompt.text = `Please give me specific instructions into an JSON Array of type CodeModifyInstruction to achieve this against this angular component: '${devInstruction.instruction}'. The instructions must include lines to remove as well as code fragments and the correct insertion points (line numbers). Here is an empty example of a CodeModifyInstruction object ${json}`;


    const queryString = this.apiExtendedUrl + `ai/getcodechangeinstructions`;

    var hds = { Accept: 'application/json' };
    var ret$ = this.http.post<CodeModifyInstruction[]>(queryString, [teHtml, teComponent, tePrompt, teFileName], { headers: hds});
    return ret$;
  }
  

  


  public aiEnhanceAngularFromSpec(existingAngular: AngularComponent, spec: string, componentName: string ): Observable<AngularComponent> {
    const te: TextDTO = new TextDTO();
    te.text = spec;

    const teModInstruct: TextDTO = new TextDTO();
    teModInstruct.text = componentName;

    const queryString = this.apiExtendedUrl + `ai/enhanceangularfromspec`;

    const teAngularComponetExisting: TextDTO = new TextDTO();
    teAngularComponetExisting.text = JSON.stringify(existingAngular);


    var hds = { Accept: 'application/json' };
    var ret$ = this.http.post<AngularComponent>(queryString, [ teAngularComponetExisting ,te, teModInstruct], { headers: hds});
    return ret$;
  }

  



  public aiEnhanceWebPage(existingHtml: string, modificationInstruction: string): Observable<TextDTO> {

    const teHtml: TextDTO = new TextDTO();
    teHtml.text = existingHtml;

    const teModInstruct: TextDTO = new TextDTO();
    teModInstruct.text = modificationInstruction;

    const queryString = this.apiExtendedUrl + `ai/enhancewebpage`;

    var hds = {
      Accept: 'application/json'
    };
    var ret$ = this.http.post<TextDTO>(queryString, [teHtml, teModInstruct], { headers: hds});
    return ret$;
  }

  




  public generateArtifactShareLink(patientId: string, artifactId: string, shareToId: string , shareTargetType: string): Observable<TextDTO> {

    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + `webuserinterface/generateartifactsharelink/${patientId}/${artifactId}/${shareToId}/${shareTargetType}`;
    var ret$ = this.http.post<TextDTO>(queryStirng, { headers: hds});
    return ret$;
  }


   public getVideoLink (fileId : string, apiKey : string, token : string): string
   {
      return this.apiExtendedUrl + `streaming/streamfile/${fileId}/${apiKey}/${token}`;
   }

   public getVideoList (): Observable<HelpVideoDTO[]>
   {
      var queryString = this.apiExtendedUrl + `streaming/helpvideos`;
      return this.http.get<HelpVideoDTO[]>(queryString);
   }



   public getHelpTopics (): Observable<Alchemint.WebAppItem[]>
   {
    var queryString =  this.apiExtendedUrl + `help/topics`;
     return this.http.get<Alchemint.WebAppItem[]>(queryString);
   }

   public getHealthCheckItems (): Observable<HealthCheckItem[]>
   {
    var queryString =  this.apiExtendedUrl + `setup/healthcheck`;
     return this.http.get<HealthCheckItem[]>(queryString);
   }



   public snapshotdataandfiles (name: string, comments: string): Observable<SnapshotDetails>
   {
    var queryString =  this.apiExtendedUrl + `setup/snapshotdataandfiles/${name}/${comments}`;
     return this.http.post<SnapshotDetails>(queryString, null, { headers : this.buildHeadsAppJson() } );
   }

   public purgeSnapShotDataAndFiles (id: string): Observable<boolean>
   {
    var queryString =  this.apiExtendedUrl + `setup/purgesnapshotdataandfiles/${id}`;
     return this.http.post<boolean>(queryString, null, { headers : this.buildHeadsAppJson() } );
   }

   public rollbackToSnapShotDataAndFiles (id: string): Observable<boolean>
   {
    var queryString =  this.apiExtendedUrl + `setup/rollbacktosnapshotdataandfiles/${id}`;
     return this.http.post<boolean>(queryString, null, { headers : this.buildHeadsAppJson() } );
   }

   public setToLatestDemoTemplateDataAndFiles (): Observable<boolean>
   {
    var queryString =  this.apiExtendedUrl + `setup/resetdemodatatolatest`;
     return this.http.post<boolean>(queryString, null, { headers : this.buildHeadsAppJson() } );
   }

   public resetDataToEmptyForDemoCompany (): Observable<boolean>
   {
    var queryString =  this.apiExtendedUrl + `setup/resetdatatoemptyfordemocompany`;
     return this.http.post<boolean>(queryString, null, { headers : this.buildHeadsAppJson() } );
   }

   public getSnapShotDetails (): Observable<SnapshotDetails[]>
   {
    var queryString =  this.apiExtendedUrl + `setup/getsnapshotdetails`;
     return this.http.get<SnapshotDetails[]>(queryString, { headers : this.buildHeadsAppJson() } );
   }

   public getLatestTermsAndConditions (): Observable<Alchemint.TermsAndConditions>
   {
    var queryString =  this.apiExtendedUrl + `setup/getlatesttermsandconditions`;
     return this.http.get<Alchemint.TermsAndConditions>(queryString, { headers : this.buildHeadsAppJson() } );
   }
   public getArtifactToFeaturesMap (): Observable<Alchemint.ArtifactToFeatureMap[]>
   {
    var queryString =  this.apiExtendedUrl + `setup/getartifacttofeaturesmap`;
     return this.http.get<Alchemint.ArtifactToFeatureMap[]>(queryString, { headers : this.buildHeadsAppJson() } );
   }

   public postUserTermsAndConditionsAgreedTo (loginId: string, termsAndConditions: Alchemint.TermsAndConditions): Observable<Alchemint.UserTermsAndConditionsAgreedTo>
   {
    var queryString =  this.apiExtendedUrl + `setup/postusertermsandconditionssgreedto`;
    var ut = new Alchemint.UserTermsAndConditionsAgreedTo();
    ut.loginId = loginId;
    ut.versionNumber = termsAndConditions.versionNumber;
    ut.termsAndConditionsId = termsAndConditions.id;
    return this.http.post<Alchemint.UserTermsAndConditionsAgreedTo>(queryString, ut, { headers : this.buildHeadsAppJson() } );
   }




  //  /{id}
  //  rollbacktosnapshotdataandfiles/{id}
  //  getsnapshotdetails




   public getAutoComplete (formFieldId : string): Observable<string[]>
   {
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/autocomplete/getforfield/${formFieldId}`, null);
     return this.http.get<string[]>(queryStirng, { headers : this.buildHeadsAppJson() });
   }

   public testexistingtokenvalid (): Observable<boolean>
   {
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'setup' + `/testexistingtokenvalid`, null);
     return this.http.get<boolean>(queryStirng);
   }




   public getCustomListValuesByCode (code: string): Observable<Alchemint.CustomListValue[]>
   {
    var queryString =  this.apiExtendedUrl + `customlists/getcustomlistvaluesbycode/${code}`;
     return this.http.get<Alchemint.CustomListValue[]>(queryString);
   }


   public getCustomListValues (id: string): Observable<Alchemint.CustomListValue[]>
   {
    var queryString =  this.apiExtendedUrl + `customlists/getcustomlistvalues/${id}`;
     return this.http.get<Alchemint.CustomListValue[]>(queryString);
   }

   public postAutoComplete (formFieldId : string, value : string): Observable<void>
   {
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/autocomplete/postforfield/${formFieldId}/${value}`, null);
     return this.http.post<void>(queryStirng, { headers : this.buildHeadsAppJson() });
   }


   public assignUserSignature (userid : string, filename : string, doctorsFormattedName, file : File): Observable<Alchemint.Artifact>
   {
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'sec/usermanagement' + `/assignusersignature/${userid}/${filename}/${doctorsFormattedName}`, null);

     const formData = new FormData();
     formData.append('file', file);
     return this.http.post<Alchemint.Artifact>(queryStirng, formData)
       .pipe(catchError((err) => { return this.HandleError(err);}));

    }

    public getusersignature (userid : string): Observable<Alchemint.ArtifactWithFileData>
    {
      var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'sec/usermanagement' + `/getusersignature/${userid}`, null);
      return this.http.get<Alchemint.ArtifactWithFileData>(queryStirng)
        .pipe(catchError((err) => { return this.HandleError(err);}));
    }
 
    public isEmailaddressRegistered (email : string): Observable<boolean>
    {
      var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'registeredperson' + `/isemailaddressregistered/${email}`, null);
      return this.http.get<boolean>(queryStirng)
        .pipe(catchError((err) => { return this.HandleError(err);}));
    }


    public changeUserSignaturename (userid : string, doctorsFormattedName): Observable<Alchemint.Artifact>
    {
      var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'sec/usermanagement' + `/changeusersignaturename/${userid}/${doctorsFormattedName}`, null);
       return this.http.put<Alchemint.Artifact>(queryStirng, null)
        .pipe(catchError((err) => { return this.HandleError(err);}));
    }
 
   public deleteConsult (id: string): Observable<void>
   {
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'diary' + `/deleteconsult/${id}`, null);
     return this.http.delete <void>(queryStirng, { headers : this.buildHeadsAppJson() });
   }


   public cancelConsult (id: string, cancelReason: string): Observable<void>
   {
     cancelReason = encodeURIComponent(cancelReason);
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'diary' + `/cancelconsult/${id}/${cancelReason}`, null);
     return this.http.delete <void>(queryStirng, { headers : this.buildHeadsAppJson() });
   }

   public changeConsultStatus (consult: Alchemint.Consult, status: string): Observable<boolean>
   {
    status = encodeURIComponent(status);
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'diary' + `/changeconsultstatus/${status}`, null);
     return this.http.put <boolean>(queryStirng, consult , { headers : this.buildHeadsAppJson() });
   }

   public reEnrichPatient (consult: Alchemint.Consult): Observable<boolean>
   {
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'diary' + `/reenrichpatientinihealth`, null);
     return this.http.put <boolean>(queryStirng, consult , { headers : this.buildHeadsAppJson() });
   }


   




   public findnextfreecalendarspot (loginId : string): Observable<Date>
   {
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'diary/' + `findnextfreecalendarspot/${loginId}`, null);
     return this.http.get<Date>(queryStirng)
       .pipe(catchError((err) => { return this.HandleError(err);}));
   }

   



   public deleteAdmission (id: string): Observable<void>
   {
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/deleteadmission/${id}`, null);
     return this.http.delete <void>(queryStirng, { headers : this.buildHeadsAppJson() });
   }


   public deleteArtiformInstance (id : string, artiFormId: string): Observable<boolean>
   {
    var queryStirng = this.buildUri(eServiceApis.eExtendedApi, `artiformmanagement/deletartiforminstance/${id}/${artiFormId}`, null);
    return this.http.delete <boolean>(queryStirng, { headers : this.buildHeadsAppJson() });
   }

   public deleteTask (id: string): Observable<void>
   {
     var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/deletetask/${id}`, null);
     return this.http.delete <void>(queryStirng, { headers : this.buildHeadsAppJson() });
   }

   public deleteExternalRequests(externalRequestIds: string) : Observable<boolean>
   {
      var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/deleteexrqs/${externalRequestIds}`, null);
      return this.http.delete <boolean>(queryStirng, { headers : this.buildHeadsAppJson() });
   }


   public deleteTheatre (theatreSlate: Alchemint.TheatreSlate): Observable<boolean> {
    var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/deletetheatreslate`, null);
    return this.http.delete <boolean>(queryStirng, {body :  theatreSlate, headers : this.buildHeadsAppJson()});


  }

  public getFormPreviewPdf (artiformId : string, instanceId :string, patientId : string): Observable<any>
  {

    if (!artiformId)
    {
      throw new Error('Attemt to get artiform pdf of null id');
    }

    var queryStirng : string;
    queryStirng =  this.apiExtendedUrl + 'webuserinterface' + `/pdfprint/${artiformId}/${instanceId}/${patientId}`;

    return this.http.get(queryStirng, {responseType : 'arraybuffer'})
    .pipe(catchError((err) => { return this.HandleError(err);}));

  }

  public getFormPreviewHtml (artiformId : string, instanceId :string, patientId : string): Observable<any>
  {

    if (!artiformId)
    {
      throw new Error('Attemt to get artiform pdf of null id');
    }

    var queryStirng : string;
    queryStirng =  this.apiExtendedUrl + 'webuserinterface' + `/htmlprint/${artiformId}/${instanceId}/${patientId}`;

    return this.http.get(queryStirng, {responseType : 'arraybuffer'})
    .pipe(catchError((err) => { return this.HandleError(err);}));

  }

  public async downloadFormInstancePdfPreview (artiformId : string, instanceId :string, patientId : string, funcblob : any)
  {

    const JSZipModule: any = await import('jszip');
    const JSZip = JSZipModule.default; // Explicitly use the default export
    const zipper = new JSZip();
    this.getFormPreviewPdf(artiformId, instanceId, patientId)
    .subscribe(fileData => {
      var downloadFileFormatIdentifier : any = 'uint8array';
      //var zipper: JSZip = new JSZip();

        zipper.loadAsync(fileData)
      .then((contents) => {
        var filename = Object.keys(contents.files)[0];
        zipper.file(filename).async(downloadFileFormatIdentifier)
        .then((content) => {
        let blob = new Blob([content], {type: 'blob'});
          new Observable((observer: Observer<any>) => {
            observer.next(content);
            observer.complete();
          }).subscribe(funcblob);

       }); }); });
  }

  // public downloadFormInstanceHtmlPreview (artiformId : string, instanceId :string, patientId : string) : Observable<any>
  // {
  //   return new Observable<any> (
  //     observer => {
  //       this.getFormPreviewHtml(artiformId, instanceId, patientId)
  //       .subscribe(
  //         fileData => {
  //           var downloadFileFormatIdentifier : any = 'uint8array';
  //           var zipper: JSZip = new JSZip();
  //           zipper.loadAsync(fileData).then((contents) => {
  //             var filename = Object.keys(contents.files)[0];
  //             zipper.file(filename).async(downloadFileFormatIdentifier)
  //             .then((content) => {
  //             let blob = new Blob([content], {type: 'blob'});
  //             observer.next(content);
  //             observer.complete();
  //           });
  //         });
  //       },
  //       err => {
  //         observer.error(err);
  //         observer.complete();
  //       }
  //       );
  //     }
  //   );
  // }

  // public downloadFormInstanceHtmlPreview(artiformId: string, instanceId: string, patientId: string): Observable<any> {
  //   const JSZipModule: any = await import('jszip');
  //   const JSZip = JSZipModule.default; // Explicitly use the default export
  //   const zipper = new JSZip();
  //   return new Observable<any>((observer) => {
  //     this.getFormPreviewHtml(artiformId, instanceId, patientId)
  //       .subscribe({
  //         next: fileData => {

  //           zipper.loadAsync(fileData)
  //             .then(contents => {
  //               const filename = Object.keys(contents.files)[0];
  //               return zipper.file(filename).async('uint8array'); // Assuming 'uint8array' is correct for your case
  //             })
  //             .then(content => {
  //               // Assuming you want to work with a Blob here; adjust the MIME type as necessary
  //               const blob = new Blob([content], { type: 'application/octet-stream' });
  //               observer.next(blob); // If you want to return the blob instead of the raw content
  //               observer.complete();
  //             })
  //             .catch(err => {
  //               observer.error(err); // Properly catch and forward errors from the promise chain
  //             });
  //         },
  //         error: err => observer.error(err),
  //         complete: () => observer.complete(),
  //       });
  //   });
  // }
  

  public async downloadFormInstanceHtmlPreviewNew (artiformId: string, instanceId: string, patientId: string): Promise<any> {
    var result: any = await firstValueFrom(this.getFormPreviewHtml(artiformId, instanceId, patientId));

    return result;
  }


  public downloadFormInstanceHtmlPreview(artiformId: string, instanceId: string, patientId: string): Observable<any> {
    // No need to use 'await' here; instead, handle the dynamic import with promise chaining.
    return new Observable<any>((observer) => {
      import('jszip').then(JSZipModule => {
        const JSZip = (<any>JSZipModule).default; // Explicitly use the default export
        const zipper = new JSZip();
        
        this.getFormPreviewHtml(artiformId, instanceId, patientId)
          .subscribe({
            next: fileData => {
              zipper.loadAsync(fileData)
                .then(contents => {
                  const filename = Object.keys(contents.files)[0];
                  return zipper.file(filename).async('uint8array'); // Assuming 'uint8array' is correct for your case
                })
                .then(content => {
                  // Assuming you want to work with a Blob here; adjust the MIME type as necessary
                  const blob = new Blob([content], { type: 'application/octet-stream' });
                  observer.next(blob); // If you want to return the blob instead of the raw content
                  observer.complete();
                })
                .catch(err => {
                  observer.error(err); // Properly catch and forward errors from the promise chain
                });
            },
            error: err => observer.error(err),
            complete: () => observer.complete(),
          });
      }).catch(err => observer.error(err)); // Catch errors from the dynamic import
    });
  }



  public getActivatedicd10CodesWebGuiApiCall (): Observable<Alchemint.ICD10Code[]>
   {

     if (this.cachedICD10CodesObservable == null)
     {
        var hds = {
          Accept: 'application/json'
        };

        var queryStirng =  this.apiExtendedUrl + 'webuserinterface' + '/geticd10codes';
        var ret$ = this.http.get<Alchemint.ICD10Code[]>(queryStirng, { headers : hds });
        this.cachedICD10CodesObservable = ret$;

     }
     return this.cachedICD10CodesObservable;
   }


   public postExternalWebRequestApiCall<T>(requestDetails: string, requestId: string, webUIControllerapiKey: string, postObject: any, submitAllowKey: string): Observable<T> {
    var currDate: Date = new Date();
    var timeZoneHoursOffset: number = Math.round(((-1) * (currDate.getTimezoneOffset() / 60)));

    var hds = {
      Accept: 'application/json',
      WebUIControllersApiKey: webUIControllerapiKey,
      "Content-Type": 'application/json'
    };

    var x = encodeURIComponent(requestDetails);
    var queryStirng = this.apiWebUiUrl + 'externalapirequest' + '/' + encodeURIComponent(requestDetails) + '/' + encodeURIComponent(requestId) + '/' + timeZoneHoursOffset;
    if (submitAllowKey) {
      queryStirng += '/' + submitAllowKey;
    }

    return this.http.post<T>(queryStirng, postObject, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }


  public verifyOTPForExternalWebRequestApiCall<T>(otp: string, requestDetails: string, requestId: string, webUIControllerapiKey: string, postObject: any, submitAllowKey: string): Observable<T> {
    var currDate: Date = new Date();
    var timeZoneHoursOffset: number = Math.round(((-1) * (currDate.getTimezoneOffset() / 60)));

    var hds = {
      Accept: 'application/json',
      WebUIControllersApiKey: webUIControllerapiKey,
      "Content-Type": 'application/json'
    };

    var x = encodeURIComponent(requestDetails);
    var queryStirng = this.apiWebUiUrl + 'externalapirequest/verifyotpforexternalapirequest' + '/' + otp + '/' + encodeURIComponent(requestDetails) + '/' + encodeURIComponent(requestId) + '/' + timeZoneHoursOffset;
    if (submitAllowKey) {
      queryStirng += '/' + submitAllowKey;
    }

    return this.http.post<T>(queryStirng, postObject, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }


  public verifyPasswordForExternalWebRequestApiCall<T>(password: string, requestDetails: string, requestId: string, webUIControllerapiKey: string, postObject: any, submitAllowKey: string): Observable<T> {
    var currDate: Date = new Date();
    var timeZoneHoursOffset: number = Math.round(((-1) * (currDate.getTimezoneOffset() / 60)));

    var hds = {
      Accept: 'application/json',
      WebUIControllersApiKey: webUIControllerapiKey,
      "Content-Type": 'application/json'
    };

    var x = encodeURIComponent(requestDetails);
    var queryStirng = this.apiWebUiUrl + 'externalapirequest/verifypasswordforexternalapirequest' + '/' + encodeURIComponent(password) + '/' + encodeURIComponent(requestDetails) + '/' + encodeURIComponent(requestId) + '/' + timeZoneHoursOffset;
    if (submitAllowKey) {
      queryStirng += '/' + submitAllowKey;
    }

    return this.http.post<T>(queryStirng, postObject, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }


  public verifyRegisteredPersonExistsForExternalWebRequestApiCall<T>(requestDetails: string, requestId: string, webUIControllerapiKey: string, postObject: any, submitAllowKey: string): Observable<T> {
    var currDate: Date = new Date();
    var timeZoneHoursOffset: number = Math.round(((-1) * (currDate.getTimezoneOffset() / 60)));

    var hds = {
      Accept: 'application/json',
      WebUIControllersApiKey: webUIControllerapiKey,
      "Content-Type": 'application/json'
    };

    var x = encodeURIComponent(requestDetails);
    var queryStirng = this.apiWebUiUrl + 'externalapirequest/verifyregistereduserexistsforexternalapirequest' + '/' + encodeURIComponent(requestDetails) + '/' + encodeURIComponent(requestId) + '/' + timeZoneHoursOffset;
    if (submitAllowKey) {
      queryStirng += '/' + submitAllowKey;
    }

    return this.http.post<T>(queryStirng, postObject, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }



  // private get defaultHttpHeaders () : string
  // {

  // }

  private buildHeadsAppJson () : any
  {
     var hds = { Accept: 'application/json' };
     return hds;
  }
  private buildHeadsAppText () : any
  {
     var hds = { Accept: 'application/text' };
     return hds;
  }

  private buildUri (apiOption : eServiceApis, path : string, qry : string) : string
  {
     var queryStirng : string;
     if (apiOption == eServiceApis.eCoreApi)
     {
       queryStirng =  this.apiUri;
     }
     else if (apiOption == eServiceApis.eExtendedApi)
     {
       queryStirng =  this.apiExtendedUrl;
     }
     else if (apiOption == eServiceApis.eBIApi)
     {
       queryStirng =  this.apiExtendedUrl;
     }

     queryStirng = queryStirng + path;

     if (qry)
     {
      queryStirng = queryStirng + "?" + qry;
     }

     return queryStirng;
  }

  // public sendEmailAnyone (emailAddresses : string, aboutPatientId : string, subject : string,  storeSentEmail : boolean, body : string, attachmentArtifactId : string, attachmentFile : File): Observable<boolean>
  // {

  //   if (!subject)
  //   {
  //     return this._getObeserverError('No email subject provided.');
  //   }

  //   if (!body)
  //   {
  //     return this._getObeserverError('No email body provided.');
  //   }


  //   var hds = {
  //     Accept: 'application/json'
  //   };
  //   var queryStirng =  this.apiExtendedUrl + 'webuserinterface' + '/sendanyoneemail/' + `${emailAddresses}/${aboutPatientId}/${subject}/${storeSentEmail}` ;

  //   if (attachmentArtifactId)
  //   {
  //     queryStirng += `/${attachmentArtifactId}`;
  //   }

  //   const formData = new FormData();
  //   if (attachmentFile)
  //   {
  //     formData.append('file', attachmentFile);
  //     formData.append('readablefilename', attachmentFile.name);
  //   }

  //   formData.append('emailbody', body);

  //   var ret$ = this.http.post<boolean>(queryStirng, formData, { headers : hds });
  //   return ret$;
  // }

  public sendEmailAnyone(
    emailAddresses: string, 
    aboutPatientId: string, 
    subject: string, 
    storeSentEmail: boolean, 
    body: string, 
    attachmentArtifactId: string, 
    attachmentFiles: File[], 
    ccEmailAddresses: string
  ): Observable<EmailResultDTO> {
    if (!subject) {
      return this._getObeserverError('No email subject provided.');
    }

    if (!body) {
      return this._getObeserverError('No email body provided.');
    }

    // const hds = { Accept: 'application/json' };
    const hds = {  };
    let queryString = this.apiExtendedUrl + 'webuserinterface' + '/sendanyoneemailnew/' +
                      `${emailAddresses}/${aboutPatientId}/${subject}/${storeSentEmail}`;

    if (attachmentArtifactId) {
      queryString += `/${attachmentArtifactId}`;
    }

    const formData = new FormData();
    // if (attachmentFiles && attachmentFiles.length > 0) {
    //   attachmentFiles.forEach((file, index) => {
    //     formData.append(`files[${index}]`, file);
    //     formData.append(`readablefilenames[${index}]`, file.name);
    //   });
    // }

    if (attachmentFiles && attachmentFiles.length > 0) {
      attachmentFiles.forEach(file => {
        if(file)
        {
          formData.append('files', file);
          formData.append('readablefilenames', file.name);
        }
      });
  }

    formData.append('ccEmails', ccEmailAddresses);
    formData.append('emailbody', body);
    return this.http.post<EmailResultDTO>(queryString, formData, { headers: hds }).pipe(catchError((err) => { return this.HandleError(err);}));;
  }

  public sendOrgSmtpTestEmail(emailAddress : string) : Observable<EmailResultDTO>
  {
    return new Observable<EmailResultDTO>(
      observer => {
        var sub = this.sendEmailAnyone(emailAddress, null, "Test Email", false, "This is a test email sent via Alchemed App.", null, null, null).subscribe(
          res=> { observer.next(res); },
          err => { observer.error(err);} ,
          () => {
            observer.complete();
            sub.unsubscribe();
          }
        );

      }
    );


  }


  // public _getConsultsSingleUser (userId : string, sFrom : string, sTo : string): Observable<Alchemint.Consult[]>
  // {
  //   //Note that dates must be in format 'dd MMM yyyy'
  //   let queryStirng = this.apiUri + `consults/getconsultsfordaterange/${userId}/${sFrom}/${sTo}`;
  //   return this.http.get<Alchemint.Consult[]>(queryStirng)
  //     .pipe(catchError((err) => { return this.HandleError(err);}));
  // }

  private _getConsultsSingleUser (userId : string, sFrom : string, sTo : string): Observable<Alchemint.Consult[]>
  {
    var queryStirng =  this.apiExtendedUrl + 'diary' + `/getconsultsfordaterange/${userId}/${sFrom}/${sTo}`;
    return this.http.get<Alchemint.Consult[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }
  private _getConsultsAllUser (sFrom : string, sTo : string): Observable<Alchemint.Consult[]>
  {
    //Note that dates must be in format 'dd MMM yyyy'
    let queryStirng = this.apiExtendedUrl + 'diary' + `/getconsultsfordaterange/${AlcConstants.ALL_IDS_IDENTIFIER}/${sFrom}/${sTo}`;
    return this.http.get<Alchemint.Consult[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));

  }

  // public cachediHealthVenues:  iHealthVenuesDTO[] = null;
  
  public getiHealthVenues (): Observable<iHealthVenuesDTO[]>
  {
    let queryStirng = this.apiExtendedUrl + 'diary' + `/getihealthvenues`;
    return this.http.get<iHealthVenuesDTO[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  public cachediHealthVenuesByLoginId:  iHealthVenuesByLoginIdDTO[] = null;
  
  public getiHealthVenuesbyLoginid (): Observable<iHealthVenuesByLoginIdDTO[]>
  {
    let queryStirng = this.apiExtendedUrl + 'diary' + `/getihealthvenuesbyloginid`;
    return this.http.get<iHealthVenuesByLoginIdDTO[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  


  public getiHealthPractitioners (): Observable<iHealthPracticePractitionerDTONew[]>
  {
    let queryStirng = this.apiExtendedUrl + 'diary' + `/getihealthpractitioners`;
    return this.http.get<iHealthPracticePractitionerDTONew[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }


  

  public cachediHealthAppointmentTypes:  iHealthAppointmentTypesDTO[] = null;

  public getiHealthAppointmentTypes (): Observable<iHealthAppointmentTypesDTO[]>
  {
    let queryStirng = this.apiExtendedUrl + 'diary' + `/getihealthappointmenttypes`;
    return this.http.get<iHealthAppointmentTypesDTO[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err);}));
  }

  private _getConsultsMultiUser (userIds : string [], sFrom : string, sTo : string): Observable<Alchemint.Consult[]>
  {
   return new Observable<Alchemint.Consult[]> (
        observer => {

          this._getConsultsAllUser(sFrom, sTo).subscribe(
            consults => {
              if (consults?.length > 0)
              {
                observer.next(null);
              }
              else
              {
                observer.next(consults?.filter(x => userIds.includes(x.userId)));
              }

            }
          );
        }
      );
  }
  public getConsults (userIds : string, sFrom : string, sTo : string): Observable<Alchemint.Consult[]>
  {

    if (userIds === AlcConstants.ALL_IDS_IDENTIFIER)
    {
      throw "Not implemented for All users";
      return this._getConsultsAllUser(sFrom, sTo);
    }
    else {
      if (userIds.indexOf(',') > 0)
      {
        var ids = userIds.split(',');
        return this._getConsultsMultiUser(ids, sFrom, sTo);
      }
      else
      {
        return this._getConsultsSingleUser(userIds, sFrom, sTo);
      }
    }
  }
}

export enum eServiceApis
{
  eCoreApi,
  eExtendedApi,
  eBIApi
}


export class FormFileDataDto
{
  constructor(public TheFile : File,  public FileName : string)
  {

  }
}

export class ReportDto
{
    public html : string;
}


export class canDeletePatientDTO
{
  public result: boolean;
  public reason: string;
  public isLinkedToiHealthAppointment: boolean;
}

export class DeveloperInstruction
{
    public instruction: string;
}