import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpaSettings } from '@app/_alchemint/alchemint_dm';
import { AuthenticationService } from '@app/_services';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { EnvService } from '@app/_services/environment.service';
import { catchError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InLineAssistanceService {
  


  public getVideoLink(fileId: string): string {
    return this.webApiInterfaceService.getVideoLink(fileId, this.authenticationService.apiKeyValue, this.authenticationService.storedBearerToken);
  }

  public cacheInLineAssistanceItemToLocalStorage(inLineAssistanceItem: InLineAssistanceItem) : void
  {
    localStorage.setItem(inLineAssistanceItem.id, JSON.stringify(inLineAssistanceItem));
  }


  private _inLineAssistanceItems : InLineAssistanceItem [];
  private _inLineAssistanceTopicItems : InLineAssistanceHelpTopic [];
  private _helpFileTopics : HelpFileTopic [];
  private _releaseNoteItems : ReleaseNoteItem [];

  private inlineAssistanceItemRequestedKeys : string [] = [];

  public get AllowContentEditing () : boolean 
  {
    //return false;
    return (this.envService.deploymentSettings.production === false);
  }
  constructor(
    public apiInterfaceService  : ApiInterfaceService, private envService : EnvService, 
    private webApiInterfaceService: WebApiInterfaceService, private authenticationService: AuthenticationService) { 

    this._inLineAssistanceItems = envService.inLineAssistanceItems();

    this._inLineAssistanceTopicItems = envService.inLineAssistanceHelpTopics();
    this._helpFileTopics = envService.helpFileTopics();
    this._releaseNoteItems = envService.releaseNoteItems();
    //this._inLineAssistanceItems.push(new InLineAssistanceItem ("SET.SHOWDEVELOPERTOOLS", "Enables devloper features in the Web App.", ""));
    //this._inLineAssistanceItems.push(new InLineAssistanceItem ("SET.USEAPIKEYFILE", "Use Api Key file to allow easy switching between multiple practices.", "https://sys.alchemed.com/app/index.html#/content/2a2ea1ca-0de9-499d-ba39-72ced2ff3de9/HLP.EHELPCONTENT.USINGANAPIKEYFILE"));
  }


  public getInLineAssistanceItem (id : string) : InLineAssistanceItem
  {

    if (this.inlineAssistanceItemRequestedKeys.filter(x => x === id).length <= 0)
    {
      this.inlineAssistanceItemRequestedKeys.push(id);
    }



    let find = this._inLineAssistanceItems.filter(x => x.id.toLocaleUpperCase() === id.toLocaleUpperCase()); 
    if (find?.length > 0)
    {
      return find[0];
    }
    else
    {
      return null;
    }
  }

  public getAllInLineAssistanceItems () : InLineAssistanceItem[]
  {

    let find = this._inLineAssistanceItems; 
    
    find.sort((x,y) => x.category < y.category ? -1 : 0);

    if (find?.length > 0)
    {
      return find;
    }
    else
    {
      return null;
    }
  }
  public getAllInLineAssistanceTopicItems () : InLineAssistanceHelpTopic[]
  {

    let find = this._inLineAssistanceTopicItems; 
    
    find.sort((x,y) => x.position < y.position ? -1 : 0);

    if (find?.length > 0)
    {
      return find;
    }
    else
    {
      return null;
    }
  }
  public getAllHelpFileTopicTopicItems () : HelpFileTopic[]
  {

    let find = this._helpFileTopics; 
    
    find.sort((x,y) => x.position < y.position ? -1 : 0);

    if (find?.length > 0)
    {
      return find;
    }
    else
    {
      return null;
    }
  }

  public getAllHReleaseNoteItems () : ReleaseNoteItem[]
  {

    let find = this._releaseNoteItems; 
    
    find.sort((x,y) => x.version < y.version ? -1 : 0);

    if (find?.length > 0)
    {
      return find;
    }
    else
    {
      return null;
    }
  }

  

  public getAllInLineAssistanceCategories () : string []
  {
    return this._inLineAssistanceItems.map(x => x.category).filter(this.onlyUnique);
  }

  
  private onlyUnique(value, index, self) : boolean
  {
    return self.indexOf(value) === index;
  }

  public generateHelpContent() : InLineAssistanceItem[]
  {
    var modData : InLineAssistanceItem[] = [];

    var savedIds = localStorage.getItem("HELPFILE.ITEM.IDS");
    var savedIdsArray : string [];
    if (savedIds)
    {
      savedIdsArray = savedIds.split(',');
    }
    else
    {
      savedIdsArray = [];
    }
    for(let i of this.inlineAssistanceItemRequestedKeys)
    {
      savedIdsArray.push(i);
    }
    for (let item of this._inLineAssistanceItems) 
    {
      savedIdsArray.push(item.id);
    }
    savedIdsArray = savedIdsArray.filter(this.onlyUnique);
    localStorage.setItem("HELPFILE.ITEM.IDS", savedIdsArray.join(','));


    for (let item of this._inLineAssistanceItems) {
      var edited = localStorage.getItem(item.id);
      if (edited)
      {
        var deserialized : InLineAssistanceItem = JSON.parse(edited);
        modData.push(new InLineAssistanceItem(deserialized.id, deserialized.toolTip, deserialized.helpLink, deserialized.title, deserialized.category, deserialized.videoLink));
      }
      else
      {
        modData.push(new InLineAssistanceItem(item.id, item.toolTip, item.helpLink, item.title, item.category, item.videoLink));
      }
    }   
    
    for (let rqItem of savedIdsArray)
    {
      if (modData.filter(x => x.id === rqItem).length <= 0)
      {
        var edited = localStorage.getItem(rqItem);
        if (edited)
        {
          var deserialized : InLineAssistanceItem = JSON.parse(edited);
          modData.push(new InLineAssistanceItem(deserialized.id, deserialized.toolTip, deserialized.helpLink, deserialized.title, deserialized.category, deserialized.videoLink));
        }
        else
        {
          modData.push(new InLineAssistanceItem(rqItem, '', '', '', '', ''));
        }
      }
    }



    return modData.sort((x,y) => x.id > y.id ? 0 : -1);
  }
 
  
  public getHelpDocuments () : HelpDocument[]
  {
    var docs: HelpDocument[] = [];

    docs.push(new HelpDocument('Considerations when transitioning from Windows App to Web App', 'https://cdn.alchemed.com/app/index.html#/content/2a2ea1ca-0de9-499d-ba39-72ced2ff3de9/HLP.EHELPCONTENT.WEBAPPCONSIDERATIONS'));
    docs.push(new HelpDocument('Document and Form Templates', 'https://cdn.alchemed.com/app/index.html#/content/2a2ea1ca-0de9-499d-ba39-72ced2ff3de9/HLP.EHELPCONTENT.TEMPLATES'));

    docs.push(new HelpDocument('Accessing Web App from any Device', 'https://cdn.alchemed.com/app/index.html#/content/2a2ea1ca-0de9-499d-ba39-72ced2ff3de9/HLP.EHELPCONTENT.INSTAPP'));

    docs.push(new HelpDocument('Using an Api Key File in Web App', 'https://cdn.alchemed.com/app/index.html#/content/2a2ea1ca-0de9-499d-ba39-72ced2ff3de9/HLP.EHELPCONTENT.USINGANAPIKEYFILE'));

    return docs;
  }


  public getWhatsNewDocuments (): HelpDocument[]
  {
    var docs: HelpDocument[] = [];
    docs.push(new HelpDocument(`What's new in Alchemed 3`, 'https://cdn.alchemed.com/app/index.html#/content/2a2ea1ca-0de9-499d-ba39-72ced2ff3de9/WN.EWHATSNEW.VER3'));
    docs.push(new HelpDocument(`What's new in Alchemed 4`, 'https://cdn.alchemed.com/app/index.html#/content/2a2ea1ca-0de9-499d-ba39-72ced2ff3de9/WN.EWHATSNEW.VER4'));
    return docs;
  }

}




export class InLineAssistanceItem 
{
  constructor(public id : string, public toolTip : string, public helpLink : string, public title : string, public category : string, public videoLink : string)
  {

  }


  public useIFrmaeForVideos : boolean = false;

}

export class InLineAssistanceHelpTopic
{
  constructor(public category : string, public position : number)
  {

  }
}

export class HelpFileTopic
{
  constructor(public title : string,  public markdown : string, public position : number)
  {

  }
}


export class ReleaseNoteItem
{
  constructor(public name : string, public detail : string, public date : string, public version : string, public visible : boolean)
  {

  }
}





export class VideoLibrary
{
  static videoLibrary : VideoFile[] = null;

  public static getVideoLibrary() : VideoFile[]
  {
    if (this.videoLibrary === null)
    {
      this.videoLibrary = [];
      
      var videoLibraryStrings : [string, string, string, boolean][] = [
        ['Unassisted Setup Video Project.mp4','UNASSISTED', 'Setup', false],
        ['Adding a new patient.mp4','ADDPAT', 'Basics', false],
        ['Adding Files to Patient in Alchemed.mp4','ADDFILES', 'Basics', false],
        ['Capturing Clinical Notes.mp4','CLINNOTES', 'Basics', false],
        ['Using Shortcut Keys in Clinical Notes.mp4','SHORTCUTKEYS', 'Basics', false],
        ['Image Editor.mp4','IMAGEEDITOR', 'Basics', false],
        ['A.I. Assisted Dictation.mp4','AIDICTATION', 'AI Assistant', false],
        ['A.I. Hand written note digitization.mp4','AIHANDWRITTEN', 'AI Assistant', false],
        ['Sharing Files.mp4','SHARINGFILES', 'File Sharing', false],
        ['Contact or Patient Shared document experience.mp4', 'SHAREDDOCEXPERIENCE','Contact or Patient Shared document experience', false],
        ['Using Patient QR Code in your rooms.mp4','PATIENTQRCODE', 'Patient', false],
        ['Forms and Templates - Part 1.mp4','FORMSTEMPLATES1', 'Forms & Templates', false],
        ['Forms and Templates - Part 2.mp4','FORMSTEMPLATES2', 'Forms & Templates', false],
        ['Forms and Templates - Part 3.mp4','FORMSTEMPLATES3', 'Forms & Templates', false],
        ['Forms and Templates - Part 4.mp4','FORMSTEMPLATES4', 'Forms & Templates', false],
        ['Forms and Templates - Part 5.mp4','FORMSTEMPLATES5', 'Forms & Templates', false],
        ['A.I. Assisted Form Generator.mp4', 'AIASSISTEDFORMGENERATOR', 'Forms & Templates', false],
        ['Linking a patient to an existing appointment in iHealth_MARK.mp4','LINKPATIENT', 'iHealth', false],
        ['How Alchemed and iHealth users are integrated_MARK.mp4','INTEGRATEDUSERS', 'iHealth', false],
        ['Submitting a Billing Form to iHealth.mp4', 'SUBMITBILLFORMTOIHEALTH', 'iHealth', false],
        ['Patient Batch Upload with CSV File.mp4','PATIENTUPLOADCSV', 'Setup', false],
        ['Template Conversion when migrating from Windows App to Web App.mp4','TEMPLATECONVERSION', 'Setup', false],
        ['Per User Letterhead, Script and Sick Note.mp4', 'PERUSERLETTERHEAD', 'Advanced', false ], 
        ['Camera Settings.mp4', 'CAMERASETTINGS', 'Advanced', false ],
        ['Adding Prices.mp4', 'INVADDPRICES', 'Invoicing Module', false  ], 
        ['Invoicing a Patient.mp4', 'INVINVOICEPAT', 'Invoicing Module', false  ], 
        ['Generating a Statement.mp4', 'INVSTATEMENT', 'Invoicing Module', false  ], 
        ['Recording a Payment Received.mp4', 'INVPAYMENTS', 'Invoicing Module', false  ], 
        ['Posting a Credit Note.mp4', 'INVCREDNOTE', 'Invoicing Module', false  ], 
        ['The Debtors Ledger and other useful reports.mp4', 'INVREPORTS', 'Invoicing Module', false  ], 
        ['Alchemed Rehab Module.mp4', 'REHAB1', 'Rehab Module', false  ], 
      ];
  
      var i: number = 1; 
      videoLibraryStrings.forEach(x => 
      {
        this.videoLibrary.push(new VideoFile(i, x[0], x[1], x[2], x[3]));
        i ++;
      });
          
      return this.videoLibrary;
  
    }
    else
    {
      return this.videoLibrary;
    }
  }

  public static getVideoFileName(code : string) : VideoFile
  {
    return this.getVideoLibrary().filter(x => x.code === code)[0];
  }

}


export class VideoFile
{
  constructor(public id : number, public title : string, public code: string, public category: string, public isBetaFeature )
  {

  }
}


export class HelpDocument
{
  constructor(public title : string, public link: string)
  {

  }
}
